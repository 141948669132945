<template>
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			id="mask0_3699_10017"
			style="mask-type: alpha"
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="20"
			height="20"
		>
			<rect width="20" height="20" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_3699_10017)">
			<path
				d="M15.8333 12.5V10.8333H4.16667V12.5H15.8333ZM15.8333 9.16667V7.5H4.16667V9.16667H15.8333ZM15.8333 5.83333V4.16667H4.16667V5.83333H15.8333ZM4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H15.8333C16.2917 2.5 16.684 2.66319 17.0104 2.98958C17.3368 3.31597 17.5 3.70833 17.5 4.16667V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667ZM15.8333 15.8333V14.1667H4.16667V15.8333H15.8333Z"
				fill="#5DA9E9"
			/>
		</g>
	</svg>
</template>
