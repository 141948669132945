import { ref } from "vue";

export const models = ref<{ [key: string]: string }>(
	JSON.parse(
		process.env.VUE_APP_AI_MODELS ||
			JSON.stringify({
				"Absolute Reality V1.8.1":
					"absolutereality_v181.safetensors [3d9d4d2b]"
			})
	)
);

export const samplers = ref<string[]>(
	JSON.parse(process.env.VUE_APP_AI_SAMPLERS || JSON.stringify(["Euler a"]))
);
