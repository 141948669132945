<template>
	<svg
		width="12"
		height="12"
		viewBox="0 0 20 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1.07812 10.5959L9.57825 2.59595L18.0781 10.5959"
			stroke="currentColor"
			stroke-width="3"
		/>
	</svg>
</template>
