import { createApp } from "vue";

import App from "./App.vue";
import clickOutside from "@/directives/click-outside";
import router from "./router";

createApp(App)
	.use(router)
	.directive("click-outside", clickOutside)
	.mount("#app");
