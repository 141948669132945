<template>
	<svg
		width="205"
		height="140"
		viewBox="0 0 205 140"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M36.5873 40.2148H169.317C171.772 40.2148 174.037 42.2917 174.037 44.935V56.0745C174.037 58.7177 171.96 60.7946 169.317 60.7946H36.5873C34.1328 60.7946 31.8672 58.7177 31.8672 56.0745V44.935C31.8672 42.2917 33.944 40.2148 36.5873 40.2148Z"
			fill="#3E3666"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M39.2295 60.2295H166.484V133.297C166.484 136.696 163.841 139.339 160.442 139.339H45.2713C41.8728 139.339 39.2295 136.696 39.2295 133.297V60.2295Z"
			fill="#3E3666"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M102.856 60.2295H166.484V133.297C166.484 136.696 163.84 139.339 160.442 139.339H108.898C105.5 139.339 102.856 136.696 102.856 133.297V60.2295Z"
			fill="#221D3E"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M102.856 40.2148H169.315C171.958 40.2148 174.035 42.2917 174.035 44.935V56.0745C174.035 58.7177 171.958 60.7946 169.315 60.7946H102.856C100.401 60.7946 98.1357 58.7177 98.1357 56.0745V44.935C98.3245 42.2917 100.401 40.2148 102.856 40.2148Z"
			fill="#221D3E"
		/>
		<path
			opacity="0.2"
			d="M166.484 60.6055H39.2295V67.7801H166.484V60.6055Z"
			fill="#0F0427"
		/>
		<path
			opacity="0.2"
			d="M166.484 60.6055H102.856V67.7801H166.484V60.6055Z"
			fill="#0F0427"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M64.7168 60.6055H77.3667V133.673C77.3667 137.072 80.01 139.715 83.4085 139.715H70.7586C67.3601 139.715 64.7168 137.072 64.7168 133.673V60.6055Z"
			fill="#FB3657"
		/>
		<path
			d="M77.3667 60.6055H64.7168V67.7801H77.3667V60.6055Z"
			fill="#B3132E"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M140.994 60.6055H128.344V133.673C128.344 137.072 125.701 139.715 122.303 139.715H134.953C138.351 139.715 140.994 137.072 140.994 133.673V60.6055Z"
			fill="#FB3657"
		/>
		<path
			d="M140.995 60.6055H128.345V67.7801H140.995V60.6055Z"
			fill="#B3132E"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M64.7162 40.2148H77.3662C74.9117 40.2148 72.646 42.2917 72.646 44.935V56.0745C72.646 58.7177 74.7229 60.7946 77.3662 60.7946H64.7162C62.2618 60.7946 59.9961 58.7177 59.9961 56.0745V44.935C59.9961 42.2917 62.0729 40.2148 64.7162 40.2148Z"
			fill="#FB3657"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M140.995 40.2148H128.345C130.799 40.2148 133.065 42.2917 133.065 44.935V56.0745C133.065 58.7177 130.988 60.7946 128.345 60.7946H140.995C143.638 60.7946 145.715 58.7177 145.715 56.0745V44.935C145.715 42.2917 143.638 40.2148 140.995 40.2148Z"
			fill="#FB3657"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M101.722 40.2136H76.2335C68.1149 35.4934 64.15 29.4517 64.5276 22.2771C65.2828 5.28464 92.2819 -1.51234 101.722 32.8502V40.2136Z"
			fill="#FB3657"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M98.514 40.216C87.7521 33.419 79.8223 26.9996 79.2559 21.1467C78.6895 15.1049 83.2208 12.084 89.0737 16.6153C96.2483 22.0907 99.2692 32.6638 101.724 40.216H98.514Z"
			fill="#18102A"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M103.988 40.2136H129.477C137.596 35.4934 141.56 29.4517 141.183 22.2771C140.428 5.28464 113.429 -1.51234 103.988 32.8502V40.2136Z"
			fill="#FB3657"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M107.198 40.216C117.96 33.419 125.89 26.9996 126.456 21.1467C127.022 15.1049 122.491 12.084 116.638 16.6153C109.464 22.0907 106.443 32.6638 103.988 40.216H107.198Z"
			fill="#18102A"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M93.4163 40.2157H112.297V33.6075C112.297 31.153 110.22 29.0762 107.766 29.0762H97.7589C95.3044 29.0762 93.2275 31.153 93.2275 33.6075V40.2157H93.4163Z"
			fill="#FB3657"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M193.672 42.8595V43.0483C194.238 44.5588 194.238 46.258 193.483 47.7685C192.917 49.2789 191.595 50.6005 189.896 51.167H189.707C188.196 51.7334 186.497 51.7334 184.987 50.9782C183.476 50.4117 182.155 49.0901 181.588 47.3909V47.2021C181.022 45.6916 181.022 43.9924 181.777 42.4819C182.344 40.9715 183.665 39.6499 185.364 39.0834H185.553C187.064 38.517 188.763 38.517 190.273 39.2722C191.595 40.0275 192.917 41.1603 193.672 42.8595ZM191.406 43.6148C191.029 42.4819 190.085 41.7267 189.141 41.3491C188.196 40.9715 187.064 40.9715 185.931 41.3491C184.798 41.7267 184.043 42.6707 183.665 43.6148C183.288 44.5588 183.288 45.6916 183.665 46.8244C184.043 47.9573 184.987 48.7125 185.931 49.0901C186.875 49.4677 188.008 49.4677 189.141 49.0901C190.273 48.7125 191.029 47.7685 191.406 46.8244C191.973 45.8804 191.973 44.7476 191.406 43.6148Z"
			fill="#6F687D"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M188.007 108.564C188.007 109.319 187.441 109.696 186.686 109.696C185.93 109.696 185.553 109.13 185.553 108.564V99.1234C185.553 98.3682 186.119 97.8018 186.686 97.8018C187.441 97.8018 188.007 98.3682 188.007 99.1234V108.564Z"
			fill="#6F687D"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M191.596 102.52C192.351 102.52 192.917 103.086 192.917 103.652C192.917 104.408 192.351 104.785 191.596 104.785H182.155C181.4 104.785 181.022 104.219 181.022 103.652C181.022 102.897 181.589 102.52 182.155 102.52H191.596Z"
			fill="#6F687D"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M16.5713 40.5936C16.5713 41.3488 16.0049 41.9152 15.4385 41.9152C14.6833 41.9152 14.3057 41.3488 14.3057 40.5936V31.1533C14.3057 30.3981 14.8721 30.0205 15.4385 30.0205C16.1937 30.0205 16.5713 30.5869 16.5713 31.1533V40.5936Z"
			fill="#6F687D"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M20.158 34.5508C20.9133 34.5508 21.2909 35.1172 21.2909 35.6836C21.2909 36.25 20.7245 36.8164 20.158 36.8164H10.7178C9.96257 36.8164 9.58496 36.25 9.58496 35.6836C9.58496 35.1172 10.1514 34.5508 10.7178 34.5508H20.158Z"
			fill="#6F687D"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M24.8789 105.919V106.108C25.4453 107.618 25.4453 109.318 24.6901 110.828C24.1237 112.338 22.802 113.66 21.1028 114.227H20.914C19.4035 114.793 17.7043 114.793 16.1938 114.038C14.6834 113.471 13.3618 112.15 12.7953 110.45V110.262C12.2289 108.751 12.2289 107.052 12.9842 105.541C13.5506 104.031 14.8722 102.709 16.5714 102.143H16.7603C18.2707 101.577 19.9699 101.577 21.4804 102.332C22.9908 103.087 24.3125 104.22 24.8789 105.919ZM22.802 106.674C22.4244 105.541 21.4804 104.786 20.5364 104.409C19.5923 104.031 18.4595 104.031 17.3267 104.409C16.1938 104.786 15.4386 105.73 15.061 106.674C14.6834 107.618 14.6834 108.751 15.061 109.884C15.4386 111.017 16.3826 111.772 17.3267 112.15C18.2707 112.527 19.4035 112.527 20.5364 112.15C21.6692 111.772 22.4244 110.828 22.802 109.884C23.1796 108.94 23.1796 107.807 22.802 106.674Z"
			fill="#6F687D"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M2.60061 80.2422C3.54464 80.2422 4.29986 79.487 4.29986 78.543C4.29986 77.599 3.54464 76.8438 2.60061 76.8438C1.65659 76.8438 0.901367 77.599 0.901367 78.543C0.901367 79.487 1.65659 80.2422 2.60061 80.2422Z"
			fill="#6F687D"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M7.69729 135.94C8.64132 135.94 9.39654 135.184 9.39654 134.24C9.39654 133.296 8.64132 132.541 7.69729 132.541C6.75327 132.541 5.99805 133.296 5.99805 134.24C5.99805 135.184 6.75327 135.94 7.69729 135.94Z"
			fill="#6F687D"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M62.6407 3.39849C63.5847 3.39849 64.3399 2.64327 64.3399 1.69925C64.3399 0.75522 63.5847 0 62.6407 0C61.6966 0 60.9414 0.75522 60.9414 1.69925C60.9414 2.64327 61.8854 3.39849 62.6407 3.39849Z"
			fill="#6F687D"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M155.532 15.8585C156.476 15.8585 157.232 15.1032 157.232 14.1592C157.232 13.2152 156.476 12.46 155.532 12.46C154.588 12.46 153.833 13.2152 153.833 14.1592C153.833 15.1032 154.588 15.8585 155.532 15.8585Z"
			fill="#6F687D"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M203.301 81.1856C204.245 81.1856 205 80.4304 205 79.4864C205 78.5423 204.245 77.7871 203.301 77.7871C202.357 77.7871 201.602 78.5423 201.602 79.4864C201.602 80.4304 202.357 81.1856 203.301 81.1856Z"
			fill="#6F687D"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M190.839 134.805C191.783 134.805 192.538 134.05 192.538 133.105C192.538 132.161 191.783 131.406 190.839 131.406C189.895 131.406 189.14 132.161 189.14 133.105C189.14 134.05 189.895 134.805 190.839 134.805Z"
			fill="#6F687D"
		/>
	</svg>
</template>
