<template>
	<svg
		width="11"
		height="11"
		viewBox="0 0 13 13"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.0058 2.03535C12.0058 1.77014 11.9004 1.51578 11.7129 1.32824C11.5254 1.14071 11.271 1.03535 11.0058 1.03535L3.0058 0.985352C2.74058 0.985352 2.48623 1.09071 2.29869 1.27824C2.11115 1.46578 2.0058 1.72013 2.0058 1.98535C2.0058 2.25057 2.11115 2.50492 2.29869 2.69246C2.48623 2.87999 2.74058 2.98535 3.0058 2.98535H8.5658L0.295798 11.2754C0.20207 11.3683 0.127676 11.4789 0.0769071 11.6008C0.0261385 11.7226 0 11.8533 0 11.9853C0 12.1174 0.0261385 12.2481 0.0769071 12.3699C0.127676 12.4918 0.20207 12.6024 0.295798 12.6953C0.388761 12.7891 0.499362 12.8635 0.621221 12.9142C0.743081 12.965 0.873786 12.9911 1.0058 12.9911C1.13781 12.9911 1.26852 12.965 1.39037 12.9142C1.51223 12.8635 1.62284 12.7891 1.7158 12.6953L10.0058 4.40535V9.98535C10.0058 10.2506 10.1112 10.5049 10.2987 10.6925C10.4862 10.88 10.7406 10.9853 11.0058 10.9853C11.271 10.9853 11.5254 10.88 11.7129 10.6925C11.9004 10.5049 12.0058 10.2506 12.0058 9.98535V2.03535Z"
			fill="url(#paint0_linear_2574_9497)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_2574_9497"
				x1="-0.210628"
				y1="8.56025"
				x2="12.0058"
				y2="8.56025"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#FE036B" />
				<stop offset="1" stop-color="#FF8057" />
			</linearGradient>
		</defs>
	</svg>
</template>
