import { email } from "../refs/account";

export const upgradeToPro = () => {
	const url =
		"https://buy.stripe.com/14kcPU175azefDi8ww" +
		(email.value !== undefined
			? "?prefilled_email=" + encodeURIComponent(email.value)
			: "");

	location.replace(url);
};

export const upgradeToSuper = () => {
	const url =
		"https://buy.stripe.com/eVa7vA8zx8r69eUdQT" +
		(email.value !== undefined
			? "?prefilled_email=" + encodeURIComponent(email.value)
			: "");

	location.replace(url);
};
