export const getRelativeDate = (input: Date | string) => {
	const date = new Date(input);

	const diffInSeconds = (date.getTime() - Date.now()) / 1000;

	let value: number;
	let unit: Intl.RelativeTimeFormatUnit;

	if (Math.abs(diffInSeconds) < 60) {
		value = diffInSeconds;
		unit = "second";
	} else if (Math.abs(diffInSeconds) < 3600) {
		value = diffInSeconds / 60;
		unit = "minute";
	} else if (Math.abs(diffInSeconds) < 86400) {
		value = diffInSeconds / 3600;
		unit = "hour";
	} else if (Math.abs(diffInSeconds) < 2592000) {
		value = diffInSeconds / 86400;
		unit = "day";
	} else if (Math.abs(diffInSeconds) < 31536000) {
		value = diffInSeconds / 2592000;
		unit = "month";
	} else {
		value = diffInSeconds / 31536000;
		unit = "year";
	}

	value = Math.round(value);

	const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

	return rtf.format(value, unit);
};
