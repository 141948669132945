import { watch, onMounted, ref, reactive, watchEffect } from "vue";
import { callSd, defaultPrompt } from "@/lib/ai/call-sd";
import { loadImage } from "@/lib/load-image";
import type { Result, RetryParams } from "@/types/ai";
import { showGenerationModal } from "@/refs/modals";
import { isPaid, isLoggedIn } from "./account";

const localStorageKey = "cover-ai-results";

const loadResults = (): Result[] => {
	const storedResults = localStorage.getItem(localStorageKey);

	const loadedResults =
		typeof storedResults === "string"
			? (JSON.parse(storedResults) as Result[])
			: [];

	for (const result of loadedResults) {
		delete result.blobUrl;
	}

	return loadedResults;
};

export const results = ref<Result[]>([]);

{
	const oldResults = loadResults();

	results.value = [...(results.value || []), ...oldResults];
}

export const saveResults = () => {
	// load results in local storage
	const storedResults = localStorage.getItem(localStorageKey);

	// get succeeded local succeeded results
	const loadedResults =
		typeof storedResults === "string"
			? (JSON.parse(storedResults) as Result[])
			: [];

	const localFinishedResults = results.value.filter(
		(result) => result.status === "succeeded" && result.url
	);

	// merge both to avoid over-writing new results from different tab
	for (const result of localFinishedResults) {
		const index = loadedResults.findIndex((r) => r.id === result.id);

		if (index === -1) {
			loadedResults.unshift(result);
		} else {
			loadedResults[index] = result;
		}
	}

	// re-save
	localStorage.setItem(localStorageKey, JSON.stringify(loadedResults));
};

let generationCount = 0;

export const generateAndStore = async (
	retryParams: RetryParams,
	upscale = false
) => {
	const id = Math.random().toString().slice(2);

	results.value.unshift({
		id,
		status: "",
		retryParams
	});

	const params = new URLSearchParams();

	if (upscale) params.set("upscale", "true");
	if (generationCount++ < 12) params.set("new", "true");

	if (
		isLoggedIn.value === false &&
		generationCount > 1 &&
		generationCount % 50 === 0
	) {
		showGenerationModal.value = true;
	}

	for (const [key, value] of Object.entries(retryParams)) {
		params.set(key, value);
	}

	const status = ref<string | undefined>();

	watch(status, () => {
		const job = results.value.find((result) => result.id === id) as Result;

		console.log("setting job status", status.value);

		job.status = status.value as string;
	});

	const { url, params: sdParams } = await callSd(params, status);
	const blobUrl = await loadImage(url);

	const job = results.value.find((result) => result.id === id) as Result;

	job.blobUrl = blobUrl;
	job.url = url;
	job.params = params;

	saveResults();
};
