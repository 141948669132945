
import { ref, computed, watch } from "vue";
import processors from "@/processors.json";
import { plan, email } from "@/refs/account";
import { showProModal } from "@/refs/modals";
import { getJobEmoji } from "@/lib/v2";
import { config as arranConfig } from "@/config";
import { key } from "@/refs/account";
import { UAParser } from "ua-parser-js";
import router from "../router";

import "highlight.js/lib/common";
import hljsVuePlugin from "@highlightjs/vue-plugin";

// Import specific highlight.js theme CSS files
import "highlight.js/styles/github.css"; // Light theme
import "highlight.js/styles/github-dark.css"; // Dark theme

const inferenceUrl =
	process.env.VUE_APP_INFERENCE_URL || "https://inference.prodia.com";

export default {
	components: {
		highlightjs: hljsVuePlugin.component
	},
	setup: () => {
		const jobTypes = ([] as string[])
			.concat(...processors.map((p) => Object.keys(p.types)))
			.filter(
				(type) =>
					type.startsWith("inference.") &&
					(type.includes("txt2img") ||
						type.includes("img2img") ||
						(type.includes("mochi") && type.endsWith("v1")) ||
						type.includes("upscale") ||
						type.includes("faceswap"))
			);

		const selectedJobType = ref<(typeof jobTypes)[number]>(
			"inference.mochi1.txt2vid.v1"
		);

		// url to default output example
		const selectedJobExample = computed<string | undefined>(() => {
			const processor = processors.find(
				(processor) => selectedJobType.value in processor.types
			);

			console.log("selectedJobType", selectedJobType.value);

			console.log("processor", processor);
			// @ts-ignore
			const type = processor?.types[selectedJobType.value];

			console.log("type", type);

			console.log("type.accept.example", type?.accept?.example);

			return type?.accept?.example;
		});

		type JobInput = {
			label: string;
			type: string[];
			maxSize: number;
			maxWidth: number;
			maxHeight: number;
			minWidth: number;
			minHeight: number;
			description: string;
		};

		const selectedJobInputs = computed<JobInput[]>(() => {
			const processor = processors.find(
				(processor) => selectedJobType.value in processor.types
			);

			// @ts-ignore
			return processor?.types[selectedJobType.value]?.inputs || [];
		});

		const config = ref<Record<string, any>>({});
		const imageInputs = ref<File[]>([]);

		const selectedProperties = computed(() => {
			const processor = processors.find(
				(processor) => selectedJobType.value in processor.types
			);

			// @ts-ignore
			const flatternProperties = (schema: any) => {
				if ("properties" in schema)
					return flatternProperties(schema.properties);

				if ("allOf" in schema)
					return Object.assign(
						{},
						...schema.allOf.map(flatternProperties).flat()
					);

				if ("oneOf" in schema)
					return flatternProperties(schema.oneOf[0]);

				return schema;
			};

			if (processor === undefined) return undefined;

			const schema = flatternProperties(processor.schema);

			console.log(schema);

			const _config = schema?.config || schema?.properties?.config || {};

			return flatternProperties(_config);
		});

		const defaultConfig = computed<Record<string, any>>(() => {
			const defaultConfig = {};

			for (const key in selectedProperties.value) {
				const defaultValue =
					selectedProperties.value[key]?.default ||
					selectedProperties.value[key]?.examples?.[0] ||
					undefined;

				// @ts-ignore
				if (defaultValue !== undefined && key !== "seed") {
					// don't set a default value for seeds
					// @ts-ignore
					defaultConfig[key] = defaultValue;
				}
			}

			return defaultConfig;
		});

		watch(defaultConfig, () => {
			config.value = {};
			imageInputs.value = [];

			console.log(defaultConfig.value);

			for (const key in defaultConfig.value) {
				if (typeof defaultConfig.value[key] === "boolean") {
					config.value[key] = defaultConfig.value[key];
				}
			}

			console.log(config.value);
		});

		const payload = computed(() => {
			return {
				type: selectedJobType.value,
				config: {
					...defaultConfig.value,
					...config.value
				}
			};
		});

		type ResponseType = "image" | "video";

		const token = ref(localStorage.getItem("v2-token") || "");
		const remember = ref<boolean>(token.value !== "");
		const image = ref<string | undefined>(undefined);
		const responseType = ref<ResponseType>("image");
		const isGenerating = ref<boolean>(false);
		const generationTime = ref<number | undefined>(0);

		const imageFile = ref<File | undefined>(undefined);
		const logs = ref<string>("");

		const getHostnameString = () => {
			const parser = new UAParser();
			const browserName = parser.getBrowser().name || "Unknown Browser";
			const osName = parser.getOS().name || "Unknown OS";
			return `${browserName} on ${osName}`;
		};

		console.log("hostname", getHostnameString());

		const getExplorerToken = async (): Promise<string> => {
			const cachedToken = localStorage.getItem("explorer-token");

			if (cachedToken) {
				const { token, expires_at } = JSON.parse(cachedToken) as {
					token: string;
					expires_at: string;
				};

				if (new Date(expires_at) > new Date()) {
					return token;
				}
			}

			if (plan.value === undefined) {
				router.push("/login");
				throw new Error("User not logged in");
			}

			if (plan.value !== "super") {
				showProModal.value = true;
				throw new Error("User not Pro+");
			}

			const expires_at = new Date(
				Date.now() + 59 * 60 * 1000
			).toISOString();

			try {
				const response = await fetch(
					`${arranConfig.arranUrl}/account/token`,
					{
						method: "POST",
						headers: new Headers({
							"X-Prodia-Key": key.value as string,
							Accept: "application/json",
							"Content-Type": "application/json"
						}),
						body: JSON.stringify({
							label: getHostnameString(),
							expiry: "explorer"
						})
					}
				);

				const { token } = await response.json();

				localStorage.setItem(
					"explorer-token",
					JSON.stringify({
						token,
						expires_at
					})
				);

				return token;
			} catch (err) {}

			alert("Failed to create token. Have you hit your limit?");
			throw new Error("Failed to create token");
		};

		const run = async () => {
			isGenerating.value = true;

			try {
				token.value = await getExplorerToken();
				logs.value = "";

				const startTime = Date.now();

				if (remember.value) {
					localStorage.setItem("v2-token", token.value);
				} else {
					localStorage.removeItem("v2-token");
				}

				const formData = new FormData();

				const jobBlob = new Blob([JSON.stringify(payload.value)], {
					type: "application/json"
				});

				for (const input of imageInputs.value) {
					formData.append("input", input, "image.jpg");
				}

				formData.append("job", jobBlob, "job.json");

				const response = await fetch(`${inferenceUrl}/v2/job`, {
					method: "POST",
					headers: {
						Accept: "multipart/form-data",
						Authorization: `Bearer ${token.value}`
					},
					body: formData
				});

				const _generationTime = Date.now() - startTime;

				if (response.status === 429) {
					await new Promise((resolve) => setTimeout(resolve, 1000));
					run();
				}

				const body = await response.formData();

				const jobResponse = JSON.parse(
					new TextDecoder().decode(
						await (body.get("job") as Blob).arrayBuffer()
					)
				);

				logs.value = JSON.stringify(jobResponse, null, "\t");

				for (const [key, value] of body.entries()) {
					console.log("body", { key, value });
				}

				// get image data
				const imageBlob = body.get("output") as File;

				responseType.value = imageBlob.type.includes("image")
					? "image"
					: "video";

				const imageUrl = URL.createObjectURL(imageBlob);

				image.value = imageUrl;

				imageFile.value = new File(
					[imageBlob],
					selectedJobType.value.includes("vid")
						? "video.mp4"
						: "image.jpg",
					{
						type: imageBlob.type
					}
				);

				console.log("imageFile", imageFile.value);

				generationTime.value = _generationTime;
			} finally {
				isGenerating.value = false;
			}
		};

		const copyCurl = () => {
			const curlCommand = `curl --request POST \\
--url https://inference.prodia.com/v2/job \\
     --header 'accept: image/jpeg' \\
     --header 'content-type: application/json' \\
	 --header 'authorization: Bearer ${token.value}' \\
     --data '
${JSON.stringify(payload.value, null, "\t")}
' \\
--output image.jpg
`;

			navigator.clipboard.writeText(curlCommand);
		};

		const handleFileUpload = (event: Event, index: number) => {
			const target = event.target as HTMLInputElement;

			if (target.files && target.files.length > 0) {
				const file = target.files[0];

				if (file) {
					imageInputs.value[index] = file;
				}
			}
		};

		const selectedTab = ref<"explore" | "code" | "logs">("explore");

		const javascriptCode = computed(() => {
			return `import fs from "node:fs/promises";
import { createProdia } from "prodia/v2";

const prodia = createProdia({
	token: process.env.PRODIA_TOKEN${
		selectedJobType.value.includes("mochi")
			? `,
maxRetries: Infinity`
			: ""
	}
});

(async () => {
	const job = await prodia.job(${JSON.stringify(payload.value, null, "\t")
		.split("\n")
		.map((l, i) => (i === 0 ? l : "        " + l))
		.join("\n")}, {
		accept: ${JSON.stringify(
			selectedJobType.value.includes("vid") ? "video/mp4" : "image/jpeg"
		)}
	});

	const image = await job.arrayBuffer();

	await fs.writeFile(${JSON.stringify(
		selectedJobType.value.includes("vid") ? "output.mp4" : "output.jpg"
	)}, new Uint8Array(image));
})();`;
		});

		// Add computed property for current theme
		const isDarkMode = computed(
			() => document.body.getAttribute("data-mode") === "dark"
		);

		const share = async () => {
			if (imageFile.value === undefined) return;

			if (!navigator.canShare({ files: [imageFile.value] })) return;

			const shareText = `${
				config.value.prompt ? `“${config.value.prompt}” ` : ""
			}https://app.prodia.com/explorer`;

			await navigator.share({
				files: [imageFile.value],
				title: "Generated with Prodia",
				text: shareText
			});
		};

		return {
			selectedTab,
			plan,
			jobTypes,
			selectedJobType,
			selectedJobExample,
			selectedProperties,
			config,
			payload,
			token,
			remember,

			image,
			run,
			isGenerating,
			generationTime,
			copyCurl,
			imageInputs,
			handleFileUpload,
			responseType,
			javascriptCode,
			isDarkMode,
			share,
			getJobEmoji,
			selectedJobInputs,
			logs
		};
	}
};
