<template>
	<svg
		width="18"
		height="18"
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			id="mask0_3699_10628"
			style="mask-type: alpha"
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="18"
			height="18"
		>
			<rect width="18" height="18" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_3699_10628)">
			<path
				d="M9.45156 9.00001L6.52656 6.07501C6.38906 5.93751 6.32031 5.76251 6.32031 5.55001C6.32031 5.33751 6.38906 5.16251 6.52656 5.02501C6.66406 4.88751 6.83906 4.81876 7.05156 4.81876C7.26406 4.81876 7.43906 4.88751 7.57656 5.02501L11.0266 8.47501C11.1016 8.55001 11.1547 8.63126 11.1859 8.71876C11.2172 8.80626 11.2328 8.90001 11.2328 9.00001C11.2328 9.10001 11.2172 9.19376 11.1859 9.28126C11.1547 9.36876 11.1016 9.45001 11.0266 9.52501L7.57656 12.975C7.43906 13.1125 7.26406 13.1813 7.05156 13.1813C6.83906 13.1813 6.66406 13.1125 6.52656 12.975C6.38906 12.8375 6.32031 12.6625 6.32031 12.45C6.32031 12.2375 6.38906 12.0625 6.52656 11.925L9.45156 9.00001Z"
				fill="#5DA9E9"
			/>
		</g>
	</svg>
</template>
