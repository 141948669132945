<template>
	<svg
		width="13"
		height="12"
		viewBox="0 0 13 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M0.469037 2.55329H2.32956C2.32956 3.09255 2.74678 3.5311 3.25982 3.5311H4.19008C4.70312 3.5311 5.12034 3.09255 5.12034 2.55329H11.6322C11.8889 2.55329 12.0973 2.33474 12.0973 2.06438C12.0973 1.79401 11.8889 1.57547 11.6322 1.57547H5.12034C5.12034 1.03621 4.70312 0.597656 4.19008 0.597656H3.25982C2.74678 0.597656 2.32956 1.03621 2.32956 1.57547H0.469037C0.212285 1.57547 0.00390625 1.79401 0.00390625 2.06438C0.00390625 2.33474 0.212285 2.55329 0.469037 2.55329Z"
			fill="#B2A9C5"
		/>
		<path
			d="M11.6322 5.48563H8.84139C8.84139 4.94636 8.42417 4.50781 7.91113 4.50781H6.98087C6.46783 4.50781 6.0506 4.94636 6.0506 5.48563H0.469037C0.212285 5.48563 0.00390625 5.70417 0.00390625 5.97453C0.00390625 6.2449 0.212285 6.46344 0.469037 6.46344H6.0506C6.0506 7.00271 6.46783 7.44126 6.98087 7.44126H7.91113C8.42417 7.44126 8.84139 7.00271 8.84139 6.46344H11.6322C11.8889 6.46344 12.0973 6.2449 12.0973 5.97453C12.0973 5.70417 11.8889 5.48563 11.6322 5.48563Z"
			fill="#B2A9C5"
		/>
		<path
			d="M11.6322 9.39774H5.12034C5.12034 8.85847 4.70312 8.41992 4.19008 8.41992H3.25982C2.74678 8.41992 2.32956 8.85847 2.32956 9.39774H0.469037C0.212285 9.39774 0.00390625 9.61628 0.00390625 9.88664C0.00390625 10.157 0.212285 10.3756 0.469037 10.3756H2.32956C2.32956 10.9148 2.74678 11.3534 3.25982 11.3534H4.19008C4.70312 11.3534 5.12034 10.9148 5.12034 10.3756H11.6322C11.8889 10.3756 12.0973 10.157 12.0973 9.88664C12.0973 9.61628 11.8889 9.39774 11.6322 9.39774Z"
			fill="#B2A9C5"
		/>
	</svg>
</template>
