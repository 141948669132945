<template>
	<svg
		width="12"
		height="22"
		viewBox="0 0 16 26"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14.3594 10.6976H7.46145L9.90626 0.999636L1 15.1507H7.89795L5.45313 24.8486L14.3594 10.6976Z"
			stroke="white"
			stroke-width="1.48438"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
