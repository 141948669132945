<template>
	<div class="modal-background">
		<div class="modal">
			<slot></slot>
		</div>
	</div>
</template>

<style>
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.modal-background {
	position: fixed;

	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	z-index: 100000;

	background: rgba(0, 0, 0, 0.8);

	display: flex;
	justify-content: center;
	align-items: center;

	animation: fadeIn 0.25s;
}

.modal {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	gap: 15px;
	justify-content: space-between;

	padding: 50px 50px;

	width: 350px;
	max-width: 90vw;

	background-color: var(--pageBG);
	border-radius: 20px;
}

.modal input {
	width: calc(100% - 30px);

	border: 1.00029px solid #ffffff;
	background-color: #f1f5f8;
	border-radius: 10px;

	padding: 15px;

	font-size: 15px;
}

.modal button {
	width: 100%;

	padding: 15px;
	font-size: 20px;
	color: #011228;

	background: #ffd500;
	border-radius: 10px;
	transition: 0.25s opacity;
}

.modal button:hover {
	opacity: 0.8;
}

.modal input {
	box-sizing: content-box !important;
}
</style>
