<template>
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M8.25 10.125H3.75C3.45163 10.125 3.16548 10.0065 2.9545 9.7955C2.74353 9.58452 2.625 9.29837 2.625 9V4.5C2.625 4.20163 2.74353 3.91548 2.9545 3.7045C3.16548 3.49353 3.45163 3.375 3.75 3.375C4.04837 3.375 4.33452 3.49353 4.5455 3.7045C4.75647 3.91548 4.875 4.20163 4.875 4.5V5.79938C6.43594 4.26188 8.83969 2.625 12 2.625C16.1681 2.625 18.69 5.09906 18.7959 5.20406C19.0045 5.41524 19.1212 5.70029 19.1205 5.99711C19.1199 6.29393 19.002 6.57846 18.7924 6.78871C18.5829 6.99897 18.2988 7.1179 18.002 7.11961C17.7052 7.12131 17.4197 7.00565 17.2078 6.79781C17.1666 6.75844 15.1875 4.875 12 4.875C9.28125 4.875 7.22438 6.5325 6 7.875H8.25C8.54837 7.875 8.83452 7.99353 9.0455 8.2045C9.25647 8.41548 9.375 8.70163 9.375 9C9.375 9.29837 9.25647 9.58452 9.0455 9.7955C8.83452 10.0065 8.54837 10.125 8.25 10.125ZM20.25 13.875H15.75C15.4516 13.875 15.1655 13.9935 14.9545 14.2045C14.7435 14.4155 14.625 14.7016 14.625 15C14.625 15.2984 14.7435 15.5845 14.9545 15.7955C15.1655 16.0065 15.4516 16.125 15.75 16.125H18C16.7812 17.4675 14.7188 19.125 12 19.125C8.8125 19.125 6.83344 17.2416 6.79219 17.2013C6.58027 16.9934 6.29481 16.8778 5.998 16.8795C5.70118 16.8812 5.41707 17.0001 5.20756 17.2104C4.99805 17.4206 4.88012 17.7051 4.87946 18.002C4.87881 18.2988 4.99548 18.5838 5.20406 18.795C5.31 18.9009 7.83187 21.375 12 21.375C15.1603 21.375 17.5641 19.7381 19.125 18.2006V19.5C19.125 19.7984 19.2435 20.0845 19.4545 20.2955C19.6655 20.5065 19.9516 20.625 20.25 20.625C20.5484 20.625 20.8345 20.5065 21.0455 20.2955C21.2565 20.0845 21.375 19.7984 21.375 19.5V15C21.375 14.7016 21.2565 14.4155 21.0455 14.2045C20.8345 13.9935 20.5484 13.875 20.25 13.875Z"
			fill="currentColor"
		/>
	</svg>
</template>
