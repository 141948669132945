export const loadImage = async (url: string): Promise<string> => {
	const response = await fetch(url);

	const arrayBufferView = new Uint8Array(await response.arrayBuffer());

	const blob = new Blob([arrayBufferView], {
		type: response.headers.get("content-type") as string
	});

	const urlCreator = window.URL || window.webkitURL;

	const blobUrl = urlCreator.createObjectURL(blob);

	return blobUrl;
};
