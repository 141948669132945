<template>
	<svg
		width="125"
		height="202"
		viewBox="0 0 125 202"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M125 200.726C123.179 203.905 108.607 200.272 105.874 199.136C100.182 197.093 94.49 193.687 90.1639 189.6C81.0565 180.971 76.275 167.801 76.275 155.313C76.5027 121.707 113.616 95.5946 102.004 60.8536C92.2131 31.5621 61.02 38.3741 37.5683 32.2433C25.0455 29.0644 12.5228 22.9336 5.2368 12.2615C2.50456 8.40142 0.910747 4.31424 0 0H124.772V200.726H125Z"
			fill="#140D24"
		/>
	</svg>
</template>
