<template>
	<button class="button">
		<slot></slot>
	</button>
</template>

<style scoped>
.button {
	font-size: inherit;
	font-weight: bold;
	background-color: #003f91;
	color: #fff;
	padding: 0.5rem 1rem;
	border-radius: 999px; /* big number to make sure ends are always rounded */
	transition: background-color 0.2s ease;
}

.button:hover {
	background-color: #00367d;
}
</style>
