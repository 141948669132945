<template>
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M9.01126 7.61592L14.9421 1.68473C15.3281 1.29893 15.3281 0.675149 14.9421 0.28935C14.5564 -0.0964498 13.9326 -0.0964498 13.5468 0.28935L7.61572 6.22054L1.68483 0.28935C1.29886 -0.0964498 0.67527 -0.0964498 0.289479 0.28935C-0.096493 0.675149 -0.096493 1.29893 0.289479 1.68473L6.22037 7.61592L0.289479 13.5471C-0.096493 13.9329 -0.096493 14.5567 0.289479 14.9425C0.481742 15.1349 0.73454 15.2316 0.987156 15.2316C1.23977 15.2316 1.49239 15.1349 1.68483 14.9425L7.61572 9.01131L13.5468 14.9425C13.7392 15.1349 13.9919 15.2316 14.2445 15.2316C14.4971 15.2316 14.7497 15.1349 14.9421 14.9425C15.3281 14.5567 15.3281 13.9329 14.9421 13.5471L9.01126 7.61592Z"
			fill="currentColor"
		/>
	</svg>
</template>
