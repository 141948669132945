
import { onMounted, ref, reactive, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { defaultPrompt } from "@/lib/ai/call-sd";
import { models, samplers } from "@/lib/ai/models";
import { loadImage } from "@/lib/load-image";
import { email, showLoginModal, plan, isPaid } from "@/refs/account";
import { showProModal } from "@/refs/modals";
import type { Result, RetryParams } from "@/types/ai";
import { results, saveResults, generateAndStore } from "@/refs/results-store";

import LightmodeAdvanced from "@/components/svgs/LightmodeAdvanced.vue";
import CoverAiResult from "@/components/CoverAIResult.vue";
import DownArrow from "@/components/svgs/DownArrow.vue";
import NoResults from "@/components/svgs/NoResults.vue";
import Configs from "@/components/svgs/Configs.vue";
import CField from "@/components/cover-ai/CField.vue";
import CSelect from "@/components/cover-ai/CSelect.vue";
import CSlider from "@/components/cover-ai/CSlider.vue";
import CSwitch from "@/components/cover-ai/CSwitch.vue";

const hasGenerations = computed(() =>
	results.value.some((result: { deleted?: true }) => !result.deleted)
);

const isTryExample = ref(!results.value.length);

const negativePrompt = ref("");

const advancedSettings = reactive<{
	resolution: number;
	cfg: number;
	steps: number;
	seed: number;
}>({
	resolution: 512,
	cfg: 7,
	steps: 25,
	seed: -1
});

const maxSteps = computed(() => (isPaid.value ? 50 : 30));

let isShowSettings = ref(false);

const getRandomSeed = () =>
	window.crypto.getRandomValues(new Uint32Array(1))[0] as number;

export default {
	components: {
		CoverAiResult,
		LightmodeAdvanced,
		DownArrow,
		CField,
		CSelect,
		CSlider,
		CSwitch,
		Configs,
		NoResults
	},
	setup: () => {
		const router = useRouter();

		const showClearWarning = ref(false);

		const clearGenerations = () => {
			if (showClearWarning.value === false) {
				showClearWarning.value = true;
			} else {
				localStorage.clear();
				location.reload();
			}
		};
		const prompt = ref("");
		const selectedModel = ref<string>(
			"absolutereality_v181.safetensors [3d9d4d2b]"
		);
		const selectedSampler = ref<string>("DPM++ 2M Karras");
		const selectedAspectRatio = ref<string>("square");

		const dimensions = {
			"Square - (512x512)": "square",
			"Portrait - (512x768)": "portrait",
			"Landscape - (768x512)": "landscape"
		};

		const generate = () => {
			isTryExample.value = false;

			const seed: number =
				advancedSettings.seed === -1
					? getRandomSeed()
					: advancedSettings.seed;

			const retryParams: RetryParams = {
				prompt: prompt.value,
				model: selectedModel.value,
				negative_prompt: negativePrompt.value,
				steps: advancedSettings.steps.toString(),
				cfg: advancedSettings.cfg.toString(),
				seed: seed.toString(),
				sampler: selectedSampler.value,
				aspect_ratio: selectedAspectRatio.value
			};

			return generateAndStore(retryParams);
		};

		const upscale = (result: Result) => {
			if (!result.retryParams) {
				throw new Error("No RetryParams!");
			}

			if (isPaid.value === false) {
				showProModal.value = true;
				return;
			}

			return generateAndStore(result.retryParams, true);
		};

		const repeatResult = (result: Result) => {
			if (!result.retryParams) {
				throw new Error("No RetryParams!");
			}

			prompt.value = result.retryParams.prompt;
			selectedModel.value = result.retryParams.model;
			negativePrompt.value = result.retryParams.negative_prompt;
			advancedSettings.steps = Number(result.retryParams.steps);
			advancedSettings.cfg = Number(result.retryParams.cfg);
			advancedSettings.seed = -1;

			return generateAndStore(
				{
					...result.retryParams,
					seed: getRandomSeed().toString()
				},
				false
			);
		};

		const deleteResult = (result: Result) => {
			result.deleted = true;
			saveResults();
		};

		type Example = {
			model: string;
			prompt: string;
			url: string;
			blobUrl?: string;
		};

		const examples = ref<Example[]>([
			{
				model: "v1-5-pruned-emaonly.safetensors [d7049739]",
				prompt: "lineart| vibrant| comprehensive cinematic| Carne Griffiths| Conrad Roset",
				url: "/examples/lineart.png"
			},
			{
				model: "v1-5-pruned-emaonly.safetensors [d7049739]",
				prompt: "an astronaut riding a horse on mars artstation, hd, dramatic lighting, detailed",
				url: "/examples/astronaut.png"
			},
			{
				model: "analog-diffusion-1.0.ckpt [9ca13f02]",
				prompt: "analog style portrait of a cute young woman with blonde hair",
				url: "/examples/analog-woman.png"
			},
			{
				model: "analog-diffusion-1.0.ckpt [9ca13f02]",
				prompt: "analog style Santa as a 1960s",
				url: "/examples/analog-santa.png"
			},
			{
				model: "anythingv3_0-pruned.ckpt [2700c435]",
				prompt: "1girl, brown hair, green eyes, colorful, autumn, cumulonimbus clouds",
				url: "/examples/anything-woman.png"
			},
			{
				model: "v1-5-pruned-emaonly.safetensors [d7049739]",
				prompt: "70s progressive rock artwork, funky, vibrant, acid-culture",
				url: "/examples/rock.png"
			}
		]);

		(async () => {
			const blobUrls = await Promise.all(
				examples.value.map((example) => loadImage(example.url))
			);

			for (const example of examples.value) {
				example.blobUrl = blobUrls.shift();
			}
		})();

		const tryExample = (example: Example) => {
			selectedModel.value = example.model;
			prompt.value = example.prompt;
			generate();
			isTryExample.value = false;
		};

		const resultElements = ref<any>({});

		function onShowLoginModal() {
			router.push("/login");
		}

		onMounted(() => {
			console.log("resultElements", Object.keys(resultElements.value));
		});

		const route = useRoute();

		onMounted(async () => {
			if (
				typeof route.query.model === "string" &&
				models.value[route.query.model] !== undefined
			) {
				selectedModel.value = models.value[route.query.model] as string;
			}

			if (typeof route.query.prompt === "string") {
				prompt.value = route.query.prompt;
			}
		});

		let shouldGenerate = true;

		const observer = new IntersectionObserver((entities) => {
			for (const entity of entities) {
				shouldGenerate = entity.isIntersecting;
			}
		});

		const autoGenerate = ref<HTMLElement | undefined>();

		onMounted(() => {
			if (autoGenerate.value) {
				observer.observe(autoGenerate.value);
			}
		});

		(async () => {
			for (;;) {
				if (shouldGenerate) {
					const concurrency = 20;

					const extra =
						concurrency -
						(results.value.filter((r) => !r.deleted).length % 20);

					await Promise.all(
						Array(concurrency + extra)
							.fill(undefined)
							.map(generate)
					);
				} else {
					await new Promise((resolve) => setTimeout(resolve, 1));
				}
			}
		})();

		return {
			prompt,
			hasGenerations,
			generate,
			status,
			dimensions,
			results,
			defaultPrompt,
			examples,
			tryExample,
			isTryExample,
			resultElements,
			models,
			samplers,
			selectedModel,
			selectedSampler,
			onShowLoginModal,
			email,
			advancedSettings,
			isShowSettings,
			deleteResult,
			negativePrompt,
			repeatResult,
			showProModal,
			plan,
			upscale,
			maxSteps,
			isPaid,
			selectedAspectRatio,
			clearGenerations,
			showClearWarning,
			autoGenerate
		};
	}
};
