<template>
	<svg
		width="50"
		height="42"
		viewBox="0 0 50 42"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M42.4171 22.6602H38.6289C39.015 23.7173 39.226 24.8582 39.226 26.0473V40.3647C39.226 40.8604 39.1397 41.3363 38.9827 41.7789H45.2455C47.585 41.7789 49.4882 39.8756 49.4882 37.5362V29.7312C49.4883 25.8323 46.3162 22.6602 42.4171 22.6602Z"
			fill="black"
		/>
		<path
			d="M42.4171 22.6602H38.6289C39.015 23.7173 39.226 24.8582 39.226 26.0473V40.3647C39.226 40.8604 39.1397 41.3363 38.9827 41.7789H45.2455C47.585 41.7789 49.4882 39.8756 49.4882 37.5362V29.7312C49.4883 25.8323 46.3162 22.6602 42.4171 22.6602Z"
			fill="url(#paint0_linear_777_5263)"
		/>
		<path
			d="M10.7671 26.0464C10.7671 24.8571 10.9781 23.7164 11.3642 22.6592H7.57601C3.67699 22.6592 0.504883 25.8313 0.504883 29.7303V37.5353C0.504883 39.8747 2.40809 41.778 4.74756 41.778H11.0104C10.8534 41.3352 10.7671 40.8595 10.7671 40.3637V26.0464Z"
			fill="black"
		/>
		<path
			d="M10.7671 26.0464C10.7671 24.8571 10.9781 23.7164 11.3642 22.6592H7.57601C3.67699 22.6592 0.504883 25.8313 0.504883 29.7303V37.5353C0.504883 39.8747 2.40809 41.778 4.74756 41.778H11.0104C10.8534 41.3352 10.7671 40.8595 10.7671 40.3637V26.0464Z"
			fill="url(#paint1_linear_777_5263)"
		/>
		<path
			d="M29.3253 18.9771H20.6649C16.7659 18.9771 13.5938 22.1492 13.5938 26.0482V40.3655C13.5938 41.1465 14.2269 41.7797 15.008 41.7797H34.9822C35.7633 41.7797 36.3964 41.1466 36.3964 40.3655V26.0482C36.3964 22.1492 33.2243 18.9771 29.3253 18.9771Z"
			fill="black"
		/>
		<path
			d="M29.3253 18.9771H20.6649C16.7659 18.9771 13.5938 22.1492 13.5938 26.0482V40.3655C13.5938 41.1465 14.2269 41.7797 15.008 41.7797H34.9822C35.7633 41.7797 36.3964 41.1466 36.3964 40.3655V26.0482C36.3964 22.1492 33.2243 18.9771 29.3253 18.9771Z"
			fill="url(#paint2_linear_777_5263)"
		/>
		<path
			d="M24.9963 0.227539C20.3071 0.227539 16.4922 4.04246 16.4922 8.73176C16.4922 11.9125 18.2477 14.6904 20.8403 16.1488C22.07 16.8406 23.4877 17.2359 24.9963 17.2359C26.5049 17.2359 27.9226 16.8406 29.1523 16.1488C31.745 14.6904 33.5004 11.9124 33.5004 8.73176C33.5004 4.04256 29.6855 0.227539 24.9963 0.227539Z"
			fill="black"
		/>
		<path
			d="M24.9963 0.227539C20.3071 0.227539 16.4922 4.04246 16.4922 8.73176C16.4922 11.9125 18.2477 14.6904 20.8403 16.1488C22.07 16.8406 23.4877 17.2359 24.9963 17.2359C26.5049 17.2359 27.9226 16.8406 29.1523 16.1488C31.745 14.6904 33.5004 11.9124 33.5004 8.73176C33.5004 4.04256 29.6855 0.227539 24.9963 0.227539Z"
			fill="url(#paint3_linear_777_5263)"
		/>
		<path
			d="M10.0628 8.15283C6.55601 8.15283 3.70312 11.0057 3.70312 14.5125C3.70312 18.0193 6.55601 20.8722 10.0628 20.8722C10.9523 20.8722 11.7994 20.6879 12.5689 20.3567C13.8993 19.7839 14.9962 18.77 15.675 17.5C16.1514 16.6086 16.4225 15.5918 16.4225 14.5125C16.4225 11.0058 13.5696 8.15283 10.0628 8.15283Z"
			fill="black"
		/>
		<path
			d="M10.0628 8.15283C6.55601 8.15283 3.70312 11.0057 3.70312 14.5125C3.70312 18.0193 6.55601 20.8722 10.0628 20.8722C10.9523 20.8722 11.7994 20.6879 12.5689 20.3567C13.8993 19.7839 14.9962 18.77 15.675 17.5C16.1514 16.6086 16.4225 15.5918 16.4225 14.5125C16.4225 11.0058 13.5696 8.15283 10.0628 8.15283Z"
			fill="url(#paint4_linear_777_5263)"
		/>
		<path
			d="M39.929 8.15186C36.4222 8.15186 33.5693 11.0047 33.5693 14.5115C33.5693 15.5909 33.8404 16.6077 34.3168 17.499C34.9956 18.7691 36.0925 19.783 37.4229 20.3557C38.1924 20.6869 39.0395 20.8712 39.929 20.8712C43.4358 20.8712 46.2887 18.0183 46.2887 14.5115C46.2887 11.0047 43.4358 8.15186 39.929 8.15186Z"
			fill="black"
		/>
		<path
			d="M39.929 8.15186C36.4222 8.15186 33.5693 11.0047 33.5693 14.5115C33.5693 15.5909 33.8404 16.6077 34.3168 17.499C34.9956 18.7691 36.0925 19.783 37.4229 20.3557C38.1924 20.6869 39.0395 20.8712 39.929 20.8712C43.4358 20.8712 46.2887 18.0183 46.2887 14.5115C46.2887 11.0047 43.4358 8.15186 39.929 8.15186Z"
			fill="url(#paint5_linear_777_5263)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_777_5263"
				x1="44.0581"
				y1="41.7789"
				x2="44.0181"
				y2="22.4243"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#FE036B" />
				<stop offset="1" stop-color="#FF8057" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_777_5263"
				x1="5.93409"
				y1="41.778"
				x2="5.89409"
				y2="22.4233"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#FE036B" />
				<stop offset="1" stop-color="#FF8057" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_777_5263"
				x1="24.9941"
				y1="41.7797"
				x2="24.967"
				y2="18.6957"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#FE036B" />
				<stop offset="1" stop-color="#FF8057" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_777_5263"
				x1="24.9956"
				y1="17.2359"
				x2="24.9754"
				y2="0.0176444"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#FE036B" />
				<stop offset="1" stop-color="#FF8057" />
			</linearGradient>
			<linearGradient
				id="paint4_linear_777_5263"
				x1="10.0622"
				y1="20.8722"
				x2="10.0471"
				y2="7.99587"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#FE036B" />
				<stop offset="1" stop-color="#FF8057" />
			</linearGradient>
			<linearGradient
				id="paint5_linear_777_5263"
				x1="39.9284"
				y1="20.8712"
				x2="39.9133"
				y2="7.99489"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#FE036B" />
				<stop offset="1" stop-color="#FF8057" />
			</linearGradient>
		</defs>
	</svg>
</template>
