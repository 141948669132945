import { ref, Ref } from "vue";
import { config } from "@/config";
import { key, plan } from "@/refs/account";

export const defaultPrompt = "puppies in a cloud, 4k";
const development = location.hostname === "localhost";

const fakeGenerateImage = async (
	params: URLSearchParams,
	statusRef: Ref<string | undefined>
) => {
	const urlPromise = `https://link.storjshare.io/s/jvmaetsfwqdh2diqonupibxxstxq/album-covers/0dc678d2-011a-43bc-8b5a-8f6f18d98b71.png?download=1`;

	statusRef.value = "queued";
	await new Promise((r) => setTimeout(r, 100));

	statusRef.value = "computing";
	await new Promise((r) => setTimeout(r, 100));

	const url = await urlPromise;
	statusRef.value = "succeeded";

	return {
		url,
		status: "succeeded",
		params: {
			prompt: params.get("prompt"),
			options: { sd_model_checkpoint: "sdv1_4.ckpt [7460a6fa]" }
		}
	};
};

const _callSd = development
	? fakeGenerateImage
	: async (
			params: URLSearchParams,
			statusRef: Ref<string | undefined>
	  ): Promise<{
			url: string;
			status: string;
			params: any;
	  }> => {
			{
				const prompt = params.get("prompt");

				if (typeof prompt !== "string" || prompt.trim().length < 1) {
					params.set("prompt", defaultPrompt);
				}
			}

			if (key.value !== undefined) {
				params.set("key", key.value);
			}

			const upscale: true | undefined =
				// @ts-ignore
				window.upscale;

			if (upscale === true) {
				params.set("upscale", "true");
			}

			const response = await fetch(
				`${config.arranUrl}/generate?${params}`
			);

			const job = await response.json();

			statusRef.value = job.status;

			do {
				await new Promise((r) =>
					setTimeout(r, plan.value === "free" ? 333 : 200)
				);

				const statusResponse = await fetch(
					`${config.arranUrl}/job/${job.job}`
				);

				const { status, params } = await statusResponse.json();

				let url: string | undefined;

				if (status === "succeeded") {
					console.log("done!");
					console.log(status, job);

					url = `https://images.prodia.xyz/${job.job}.png?download=1`;
				}

				if (status === "failed") {
					url = `#`;
				}

				statusRef.value = status;

				if (url !== undefined) {
					return {
						url,
						status,
						params
					};
				}
			} while (true);

			throw new Error("Bad SD Response");
	  };

const threads: Promise<any>[] = [];

let counter = 0;

const createThread = () => {
	threads.push(Promise.resolve());
};

export const ensureThreads = (n: number) => {
	while (threads.length < n) createThread();

	counter = 0;
};

ensureThreads(1);

const pushTask = (fn: () => Promise<any>) => {
	const i = counter++ % threads.length;
	const thread = threads[i];

	if (thread === undefined) {
		throw new Error("Thread undefined");
	}

	const result = thread.finally().then(fn);

	threads[i] = result;

	return result;
};

export const callSd: typeof _callSd = (params, ref) => {
	ref.value = "queued";

	const promise = pushTask(() => _callSd(params, ref));

	return promise;
};
