interface ClickOutsideHTMLElement extends HTMLElement {
	eventSetDrag: () => void;
	eventClearDrag: () => void;
	eventOnClick: (event: Event) => void;
}

interface Binding {
	value: (event: Event) => void;
}

/**
 * Click outside directive
 *
 * Enables an element to detect click events that occur outside of itself, then
 * call a method provided via the value of the directive.
 *
 * src: https://stackoverflow.com/a/65735656/827129
 */
const clickOutside = {
	beforeMount: (el: ClickOutsideHTMLElement, binding: Binding) => {
		el.eventSetDrag = () => {
			el.setAttribute("data-dragging", "yes");
		};
		el.eventClearDrag = () => {
			el.removeAttribute("data-dragging");
		};
		el.eventOnClick = (event) => {
			// typed separately as TS complains when assigning directly to the param
			const typedEvent = event as MouseEvent;
			const dragging = el.getAttribute("data-dragging");

			// Check that the click was outside the el and its children, and wasn't a drag
			if (
				!document
					.elementsFromPoint(typedEvent.clientX, typedEvent.clientY)
					.includes(el) &&
				!dragging
			) {
				// call method provided in attribute value
				binding.value(typedEvent);
			}
		};
		document.addEventListener("touchstart", el.eventClearDrag);
		document.addEventListener("touchmove", el.eventSetDrag);
		document.addEventListener("click", el.eventOnClick);
		document.addEventListener("touchend", el.eventOnClick);
	},
	unmounted: (el: ClickOutsideHTMLElement) => {
		document.removeEventListener("touchstart", el.eventClearDrag);
		document.removeEventListener("touchmove", el.eventSetDrag);
		document.removeEventListener("click", el.eventOnClick);
		document.removeEventListener("touchend", el.eventOnClick);
		el.removeAttribute("data-dragging");
	}
};

export default clickOutside;
