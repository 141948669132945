<template>
	<svg
		width="28"
		height="26"
		viewBox="0 0 28 26"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M1.67341 1.76704C0.593381 2.86883 0.557448 4.64425 1.59246 5.79066L8.15343 13.0553L8.15338 13.0555L13.0638 18.4927L13.0639 18.4925L14.1817 19.7303C14.2467 19.284 14.3547 18.8395 14.5063 18.4036C15.3606 15.9507 17.0891 14.6153 18.9442 14.2097L5.61754 1.68447C4.49377 0.628592 2.75342 0.665259 1.67341 1.76704ZM23.9289 18.8396C24.0946 20.6207 25.5041 21.3877 26.7953 21.6605C27.2965 21.7663 27.5334 22.4672 27.1274 22.8052C19.5995 29.074 14.6189 23.5976 16.2123 19.0219C17.885 14.2183 23.6338 15.6695 23.9289 18.8396ZM2.09198 19.2391L6.87227 14.3624L11.7827 19.7996L7.21207 24.4622C6.95122 24.7284 6.62141 24.9122 6.26418 24.9906L2.18802 25.8854C1.28422 26.0838 0.502475 25.2864 0.696955 24.3643L1.57405 20.206C1.65091 19.8416 1.83113 19.5051 2.09198 19.2391ZM21.3461 10.0435L19.3497 12.08L14.0199 7.07072L16.2259 4.82021L21.3461 10.0435ZM24.0711 2.04026C22.6573 0.597886 20.3279 0.635566 18.8685 2.12442L17.5473 3.47232L22.6673 8.69559L23.9886 7.34769C25.4481 5.85885 25.485 3.48263 24.0711 2.04026Z"
			fill="#6F687D"
		/>
	</svg>
</template>
