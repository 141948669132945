<template>
	<svg
		width="22"
		height="23"
		viewBox="0 0 22 23"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask id="path-1-inside-1_76_41089" fill="white">
			<path
				d="M20.0244 12.0163C19.3277 11.9102 18.709 12.3878 18.6091 13.0686C18.3581 14.7787 17.5689 16.3389 16.3266 17.5813C13.1647 20.7431 8.02375 20.7407 4.8631 17.5813C1.70308 14.4206 1.70308 9.27779 4.8631 6.11777C6.0518 4.92969 7.55243 4.15505 9.23191 3.87306C9.95416 3.73599 10.7307 3.7123 11.5364 3.80359C12.6426 3.93093 13.6723 4.29798 14.6119 4.8369L12.9882 5.11415C12.3099 5.23051 11.853 5.87481 11.97 6.55379C12.0735 7.16155 12.601 7.59083 13.1977 7.59083C13.2672 7.59083 13.3378 7.58534 13.4096 7.57312L17.7626 6.82892C18.0878 6.77292 18.3789 6.5902 18.5702 6.31981C18.7614 6.05004 18.8369 5.71505 18.7808 5.38866L18.0354 1.03631C17.9209 0.357962 17.2851 -0.0963919 16.5957 0.0174772C15.9174 0.133841 15.4605 0.778766 15.5775 1.45774L15.7737 2.60329C14.5665 1.92606 13.2349 1.48792 11.8201 1.32504C10.7605 1.20443 9.73253 1.23798 8.79165 1.4182C6.62552 1.78114 4.65731 2.79636 3.09944 4.35411C-1.03315 8.48669 -1.03315 15.2111 3.09944 19.3448C5.16567 21.4116 7.88057 22.4446 10.5948 22.4446C13.3097 22.4446 16.0233 21.4118 18.0902 19.3448C19.7138 17.7212 20.7467 15.6763 21.0767 13.4316C21.1767 12.7495 20.7052 12.116 20.0244 12.0163V12.0163Z"
			/>
		</mask>
		<path
			d="M20.0244 12.0163C19.3277 11.9102 18.709 12.3878 18.6091 13.0686C18.3581 14.7787 17.5689 16.3389 16.3266 17.5813C13.1647 20.7431 8.02375 20.7407 4.8631 17.5813C1.70308 14.4206 1.70308 9.27779 4.8631 6.11777C6.0518 4.92969 7.55243 4.15505 9.23191 3.87306C9.95416 3.73599 10.7307 3.7123 11.5364 3.80359C12.6426 3.93093 13.6723 4.29798 14.6119 4.8369L12.9882 5.11415C12.3099 5.23051 11.853 5.87481 11.97 6.55379C12.0735 7.16155 12.601 7.59083 13.1977 7.59083C13.2672 7.59083 13.3378 7.58534 13.4096 7.57312L17.7626 6.82892C18.0878 6.77292 18.3789 6.5902 18.5702 6.31981C18.7614 6.05004 18.8369 5.71505 18.7808 5.38866L18.0354 1.03631C17.9209 0.357962 17.2851 -0.0963919 16.5957 0.0174772C15.9174 0.133841 15.4605 0.778766 15.5775 1.45774L15.7737 2.60329C14.5665 1.92606 13.2349 1.48792 11.8201 1.32504C10.7605 1.20443 9.73253 1.23798 8.79165 1.4182C6.62552 1.78114 4.65731 2.79636 3.09944 4.35411C-1.03315 8.48669 -1.03315 15.2111 3.09944 19.3448C5.16567 21.4116 7.88057 22.4446 10.5948 22.4446C13.3097 22.4446 16.0233 21.4118 18.0902 19.3448C19.7138 17.7212 20.7467 15.6763 21.0767 13.4316C21.1767 12.7495 20.7052 12.116 20.0244 12.0163V12.0163Z"
			fill="#C4C4C4"
			stroke="#09001B"
			stroke-width="0.4"
			mask="url(#path-1-inside-1_76_41089)"
		/>
	</svg>
</template>
