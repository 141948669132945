<template>
	<div class="privacy-container">
		<div class="privacy">
			<h1>Prodia Privacy Policy</h1>
			<p>Last Updated: January 26th, 2023</p>
			<br />
			<p>
				Prodia is a service of Prodia Labs Inc.
				<b>(“Prodia”, “we”, “us”, “ours”)</b>. We offer software which
				empowers users to create custom AI-generated digital art and
				music (collectively our “Services”). We provide Services
				globally through our website
				<a href="https://prodia.com">https://prodia.com </a>(including
				mobile site) (the “Site”).
			</p>
			<br />
			<p>
				This Privacy Policy describes how Prodia collects, uses, shares
				and otherwise processes your personal data in connection with
				providing our Services, how you may share personal data through
				our Services, and your rights and choices concerning our privacy
				practices.
			</p>
			<br />
			<p>
				We interact with artists and other visitors who use Prodia to
				design, distribute, and view AI generated art and music. We
				refer to Prodia artists and other visitors who browse our Site,
				collectively, as <b>User</b> or <b>Users</b>.
			</p>
			<br />
			<p>
				If you have any questions or concerns about our use of your
				personal data or would like to exercise your choices in relation
				to your personal data, then please
				<a href="mailto: hello@prodia.com">Contact Us</a> using the
				contact details provided in this Privacy Policy.
			</p>
			<br />
			<h2>Personal Data That We Collect</h2>
			<br />
			<p>
				<i><b>Information You Provide</b></i>
			</p>
			<br />
			<li>
				<b>Registration. </b>When signing up to use our Services, we ask
				for your contact information, which may include items such as
				your name, address, email address, and telephone number.
			</li>
			<br />
			<li>
				<b>Payment Information. </b>When you add financial account
				information to purchase our premium Services (if available),
				that information is directed to our third-party payment
				processor. We do not store your financial account information on
				our systems; however, we have access to, and may retain, user
				information through our third-party payment processor.
			</li>
			<br />
			<li>
				<b>Other Information. </b>Our Services allow you to provide data
				in free form fields, (e.g., on our AI art and music generator or
				<a href="mailto: hello@prodia.com">Contact Us)</a>. If you
				provide us with personal data through those forms, it will be
				governed by this Privacy Policy.
			</li>
			<br />
			<li>
				<b>Communications. </b>If you contact us directly, then we may
				receive additional information about you such as your name,
				email address, phone number, the contents of the message and/or
				attachments you may send us, and any other information you may
				choose to provide. We may also receive a confirmation when you
				open an email from us.
			</li>
			<br />
			<p>
				<i><b>Automatically Collected Data</b></i>
			</p>
			<br />
			<p>
				When you use our Services, the following information is created
				and automatically logged in our systems:
			</p>
			<br />
			<li>
				<b>Log Data. </b>Information that your browser automatically
				sends whenever you visit the Site. Log data includes the
				device’s IP address, browser type and settings, the date and
				time of your request, and how you interacted with the Site. Your
				geographic location determines which Services, disclosures,
				features, and third party content will be available to you.
			</li>
			<br />
			<li>
				<b>Cookies. </b>Information from cookies and other technologies
				stored on your device (together, “Cookies”). A Cookie is a piece
				of information stored on your browser or device. We use Cookies
				to make it easier for you to use our Services during future
				visits by identifying your browser or device, and to help us
				monitor traffic on our Site. Our third party vendors and service
				providers may also place Cookies on your browser through your
				interaction with our Site. The Cookies may be used to collect
				and store information about your browsing activities over time
				and across different websites, following your interaction with
				our Site. For more detail about specific Cookies, how we use
				Cookies, and your choices regarding cookies, please see our
				<router-link to="cookie-policy"> Cookie Policy.</router-link>
			</li>
			<br />
			<li>
				<b>Device Information. </b>Includes the type of device you are
				using, operating system, settings, unique device identifiers,
				network information and other device-specific information.
				Information collected may depend on the type of device you use
				and its settings.
			</li>
			<br />
			<li>
				<b>Usage Information. </b>We collect information about how you
				use our Services such as the types and categories of content
				that you view or engage with, the features you use, the actions
				you take, the other Users you interact with and the time,
				frequency and duration of your activities. For example, this
				includes whether you open and act on marketing emails.
			</li>
			<br />
			<p><b>How We Use Personal Data</b></p>
			<br />
			<p>
				We use the personal data we collect for the following purposes:
			</p>
			<br />
			<li><b>To Provide and Support Our Services. </b></li>
			<br />
			<p>
				We will use your personal data to perform our contractual
				obligations under our terms of use, or to take steps to enter
				into a contract with you, such as:
			</p>
			<br />
			<li>
				To authenticate Users, provide our Services and related support,
				process transactions and respond to your requests.
			</li>
			<br />
			<li>
				To manage our relationship with you, which includes sending
				information to you relating to our Services, changes to our
				terms, conditions and policies, and requesting you review or
				respond to a survey.
			</li>
			<br />
			<li>
				<b
					>To Improve, Monitor, Personalize, and Protect Our
					Services.</b
				>
			</li>
			<br />
			<p>
				It is in our legitimate business interests to improve and keep
				our Services safe for our users, which includes:
			</p>
			<br />
			<li>
				To administer and protect our business and our Services, prevent
				fraud, criminal activity, or misuse of our Site, and to ensure
				the security of our IT systems, architecture and networks
				(including troubleshooting, testing, system maintenance, support
				and hosting of data).
			</li>
			<br />
			<li>
				To investigate and protect against fraudulent, harmful,
				unauthorized or illegal activity.
			</li>
			<br />
			<li>
				To better understand how visitors interact with our Services,
				including to ensure that our Site is presented in the most
				effective manner for you or your device.
			</li>
			<br />
			<li>
				To conduct analytics to inform our product and marketing
				strategy and enable us to enhance and personalize our
				communications and the experience we offer to our Users.
			</li>
			<br />
			<li>
				To provide other customized Services and communications that may
				be relevant or of interest to you.
			</li>
			<br />
			<li>
				To create anonymized, de-identified and/or aggregated data for
				commercial, statistical and market research purposes.
			</li>
			<br />
			<li>To conduct research.</li>
			<br />
			<li>
				<b
					>To Enforce Our Agreements, to Comply with Legal Obligations
					and to Defend Us Against Legal Claims or Disputes.
				</b>
			</li>
			<br />
			<p>
				We may use your personal data in our legitimate business
				interests to enforce and comply with our terms and policies;
				protect our rights, privacy, safety, or property; to ensure the
				integrity of our Services; to defend ourselves against legal
				claims or disputes; and to recover payments due to us. Some
				processing may also be necessary to comply with a legal
				obligation, for example to keep records of transactions, or as
				requested by any judicial process or governmental agency.
			</p>
			<br />
			<li><b>For Marketing and Advertising Purposes.</b></li>
			<br />
			<li>
				<b>Email Communications. </b>Email Communications. We will send
				you updates and information about our new Services or other
				promotions by email. Where required by law, we will only send
				Users marketing emails with their consent. You can unsubscribe
				or opt out at any time, via: (i) the email preference page
				linked to every email sent through our Services (unsubscribe
				here), or (ii)
				<a href="mailto: hello@prodia.com">Contact Us</a>. If you opt
				out of receiving marketing emails, we may send you non-marketing
				emails regarding your use of Prodia, and in response to your
				requests. If you have any questions about the communications we
				may send you, please
				<a href="mailto: hello@prodia.com">Contact Us</a>.
			</li>
			<br />
			<li>
				<b>Offsite Targeted Advertising. </b>We may show you Prodia
				advertising on other websites you visit, following your
				interaction with our Site, through the use of Cookies when you
				visit our Site. The purpose is to tell you about Services you
				have expressed an interest in by browsing our Site, or that we
				believe will be of interest to you in the future. You can limit
				online tracking as described in our
				<router-link to="cookie-policy"> Cookie Policy</router-link>.
			</li>
			<br />
			<p>
				Where required by law, if you choose to subscribe to our
				marketing and advertising, then we process your personal data
				based on your consent, which you may withdraw at any time.
			</p>
			<br />
			<p><b>How We Share Personal Data</b></p>
			<br />
			<p>
				We share personal data and other information for purposes
				consistent with this Privacy Policy with certain third parties
				in the following circumstances:
			</p>
			<li>
				<b>Service Providers. </b>We may share your personal data with
				third party companies and individuals that provide services and
				products on our behalf or help us operate our Services (such as
				customer support, hosting, analytics, email delivery, marketing,
				and database management services).
			</li>
			<br />
			<li>
				<b>Advertising Partners. </b>We may share your personal data
				with third party advertising companies, including for the
				offsite Prodia targeted advertising described above. For details
				on the third parties that may place Cookies through our Site,
				and information on your choices, please see our
				<router-link to="cookie-policy"> Cookie Policy</router-link>.
			</li>
			<br />
			<li>
				<b>Professional Advisors. </b> We may share personal data with
				professional advisors, such as lawyers, auditors, bankers and
				insurers, where necessary in the course of the professional
				services that they render to us.
			</li>

			<br />
			<li>
				<b>Business Transfers. </b>If we are involved in a merger,
				acquisition, financing due diligence, reorganization,
				bankruptcy, receivership, sale of all or a portion of our
				assets, or transition of a service to another provider, then
				your personal data and other information may be transferred to a
				successor or affiliate as part of that transaction.
			</li>
			<br />
			<li>
				<b>Legal Requirements. </b>If required to do so by law,
				applicable regulation or in the good faith belief that such
				action is necessary to: (i) comply with a legal obligation,
				including to meet national security or law enforcement
				requirements; (ii) protect and defend the rights or property of
				Prodia; (iii) act in urgent circumstances to protect the
				personal safety of Users, or the public; or (iv) protect Prodia
				against legal liability.
			</li>
			<p><b>How You May Share Personal Data on Prodia</b></p>
			<br />
			<p>
				We may allow Users to share their data (or direct us to share
				their data), including with other Users and the public. Users
				may share their personal data with:
			</p>
			<br />
			<li>
				<b>Other Users: </b>by sharing AI-generated content with other
				Users.
			</li>
			<br />
			<li>
				<b>Social Media Platforms: </b>by posting User content to social
				media.
			</li>
			<br />
			<li>
				<b>Public: </b>by establishing a public profile and sharing
				certain profile information publicly via our Site, (e.g.,
				AI-generated creations and the Users you follow).
			</li>
			<br />
			<p><b>Your Rights and Choices</b></p>
			<br />
			<p>
				In certain circumstances providing personal data to us is
				optional. However, if you choose not to provide personal data,
				you may be unable to use some of the features of our Services.
			</p>
			<br />
			<p><b>Communications and Service Choices</b></p>
			<br />
			<p>
				You can opt out of receiving any email communications at any
				time via: (i) the preference page linked in the footer of every
				email sent through our Services (unsubscribe here), (ii) or
				<a href="mailto: hello@prodia.com">Contact Us</a>
				.
			</p>
			<br />
			<p><b>Privacy Rights</b></p>
			<br />
			<p>
				We may also offer you choices that affect how we handle the
				personal data that we control. Where required under applicable
				laws, then you may request the following in relation to your
				personal data:
			</p>
			<li>
				<b
					>Information About How We Collect and Use Your Personal
					Data. </b
				>We have made this information available to you without having
				to request it by including it in this Privacy Policy.
			</li>
			<br />
			<li>
				<b>Access. </b>You may request access to a copy of the personal
				data that we have collected about you. If access is requested,
				then we will confirm whether we are processing your personal
				data and, if so, provide you with a copy of that personal data
				along with certain other details.
			</li>
			<br />
			<li>
				<b>Data Portability. </b>You may request to receive personal
				data in a structured, commonly used and machine-readable format
				and (if certain conditions are satisfied), transmit that
				personal data to a third party, if we have the technical means.
			</li>
			<br />
			<li>
				<b>Correction. </b>You may request the correction of personal
				data that is inaccurate or out of date for the purpose for which
				we collected or use this data.
			</li>
			<br />
			<li>
				<b>Deletion. </b>You may request the deletion of personal data
				that we no longer need to provide our Services or for other
				lawful purposes.
			</li>
			<br />
			<li>
				<b>Objection/Restriction. </b>You may also ask us to restrict or
				“block” the processing of your personal data in certain
				circumstances, such as where you contest its accuracy or object
				to us processing it. If you exercise your rights, then we will
				respect your decision in accordance with our legal obligations.
			</li>
			<br />
			<li>
				<b>Opt Out of Sharing of Your Personal Data. </b>We share
				personal data with advertising partners that display offsite
				Prodia targeted advertising to Users around the web. You can opt
				out of us sharing your personal data for these purposes by
				clicking here. Additionally, you can limit online tracking as
				described in our
				<router-link to="cookie-policy"> Cookie Policy</router-link>. We
				do not sell personal data.
			</li>
			<br />
			<li>
				<b>Withdraw Consent. </b>In circumstances where you have
				provided your consent to the collection, processing and transfer
				of your personal data for a specific purpose, you have the right
				to withdraw consent for that specific processing at any time.
			</li>
			<br />
			<p>
				To make a request, please email us or write to us as provided in
				the “Contact Us” section below. We may ask for specific
				information from you to help us confirm your identity. Depending
				on where you reside, you may be entitled to empower an
				“authorized agent” to submit requests on your behalf. We will
				require authorized agents to confirm their identity and
				authority, in accordance with applicable laws. You are entitled
				to exercise the rights described above free from discrimination.
			</p>
			<br />
			<p><b>Limits on Your Choices </b></p>
			<br />
			<p>
				In some instances, your choices may be limited, such as where
				fulfilling your request would impair the rights of others, our
				ability to provide a feature of our Services you have requested,
				or our ability to comply with our legal obligations and enforce
				our legal rights. If you are not satisfied with how we address
				your request, then you may contact us as provided in the
				“Contact Us” section below. Depending on where you reside, such
				as if you reside in the European Union or United Kingdom, you
				may have the right to complain to a data protection regulator
				where you live or work, or where you feel a violation has
				occurred.
			</p>
			<br />
			<p><b>Do Not Track </b></p>
			<br />
			<p>
				Some Internet browsers may be configured to send “Do Not Track”
				signals to the online services that you visit. We currently do
				not respond to “Do Not Track” or similar signals. To find out
				more about “Do Not Track,” please visit
				<a href="http://www.allaboutdnt.com."
					>http://www.allaboutdnt.com.</a
				>
			</p>
			<br />
			<p><b>Data Retention </b></p>
			<br />
			<p>
				We retain your personal data only for as long as is necessary to
				fulfill the purposes for which it was collected and processed,
				in accordance with our retention policies, and in accordance
				with applicable laws or until you withdraw your consent (where
				applicable).
			</p>
			<br />
			<p>
				If you have elected to receive marketing communications from us,
				then we retain information about your marketing preferences
				until you opt out of receiving these communications in
				accordance with our policies.
			</p>
			<br />
			<p>
				To determine the appropriate retention period for your personal
				data, we will consider the amount, nature, and sensitivity of
				the personal data, the potential risk of harm from unauthorized
				use or disclosure of your personal data, the purposes for which
				we use your personal data and whether we can achieve those
				purposes through other means, and the applicable legal
				requirements. In some circumstances we may anonymize your
				personal data so that it can no longer be associated with you,
				in which case it is no longer personal data.
			</p>
			<br />
			<p><b>International Data Transfers </b></p>
			<br />
			<p>
				Prodia is based in the U.S. When you use our Services, you
				provide your personal data directly to us in the U.S. We may
				transfer your personal data to our affiliates and service
				providers in the U.S. and other jurisdictions that may not
				provide the same protections as the data protection laws in your
				home country, as described in the “How We Share Personal Data”
				section above. In these instances, we will ensure that relevant
				safeguards are in place to afford adequate protection for your
				personal data, including by implementing EU Commission- or UK
				government-approved contractual protections for the transfer of
				your personal data. For more information about how we transfer
				personal data internationally, please contact us as set out in
				the “Contact Us” section below.
			</p>
			<br />
			<p><b>Children’s Privacy</b></p>
			<br />
			<p>
				Our Services are not intended for use by children. If you are a
				parent or guardian and you have reason to believe that a child
				under the age of 16 has provided personal data to Prodia through
				the Site, then please contact us as set out in the “Contact Us”
				section below.
			</p>
			<br />
			<p><b>Security</b></p>
			<br />
			<p>
				We maintain organizational, administrative and technical
				safeguards designed to protect your personal data from loss,
				misuse and unauthorized access, disclosure, alteration, or
				destruction. However, no method of transmission over the
				internet is 100% secure. While we strive to protect your data,
				we cannot guarantee its absolute security.
			</p>
			<br />
			<p><b>Other Websites, Mobile Applications, and Services</b></p>
			<br />
			<p>
				Our Services may contain links to other websites, mobile
				applications, and other online services operated by third
				parties. These links are not an endorsement of, or
				representation that we are affiliated with, any third party. In
				addition, our content may be included on web pages or in mobile
				applications or online services that are not associated with us.
				We do not control third party websites, mobile applications or
				online services, and we are not responsible for their actions.
				Other websites and services follow different rules regarding the
				collection, use and sharing of your personal data. We encourage
				you to read the privacy policies of the other websites and
				mobile applications and online services you use.
			</p>
			<br />
			<p><b>Changes to Our Privacy Policy</b></p>
			<br />
			<p>
				We may change this Privacy Policy at any time. If we make
				material changes to this Privacy Policy, we will notify you by
				updating the date of this Privacy Policy and posting it on our
				Services.
			</p>
			<br />
			<p><b>Contact Us</b></p>
			<br />
			<p>
				Prodia Labs Inc. is the entity responsible for the processing of
				your personal data, and is the data controller for the personal
				data we collect or otherwise process as described in this
				Privacy Policy. If you have any questions about our Privacy
				Policy or our data practices, or if you would like to exercise
				your rights with respect to your personal data, then please
				contact us at
				<a href="mailto: hello@prodia.com">hello@prodia.com</a> or by
				post at: <br /><br /><i
					>1155 Mount Vernon HWY NE <br />
					Suite 800 <br />
					Atlanta, GA 30338 <br />
					470-737-0633 <br />
				</i>
			</p>
		</div>
	</div>
</template>

<style scoped>
.privacy-container {
	margin-left: 70px;
}
.privacy {
	padding-top: 124px;
	display: flex;
	flex-direction: column;
	padding-bottom: 130px;
	text-align: justify;
	width: 90%;
	margin: 0 auto;
}

h2 {
	font-weight: 500;
}

a {
	cursor: pointer;
	font-weight: 500;
	text-decoration: underline;
}

a:hover {
	text-decoration: none;
}

@media screen and (max-width: 738px) {
	.privacy-container {
		margin: 0;
	}
	.privacy {
		width: 80%;
		margin: 0 auto;
		padding-top: 180px;
		text-align: left;
	}
}
</style>
