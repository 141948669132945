import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ed59755"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "stats"
}
const _hoisted_2 = {
  class: "statItemChart",
  style: {"height":"400px"}
}
const _hoisted_3 = {
  class: "statItemChart",
  style: {"height":"400px"}
}
const _hoisted_4 = {
  class: "statItemChart",
  style: {"height":"400px"}
}
const _hoisted_5 = {
  class: "statItemChart",
  style: {"height":"400px"}
}
const _hoisted_6 = {
  class: "statItemChart",
  style: {"height":"400px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_line_chart = _resolveComponent("line-chart")!
  const _component_bar_chart = _resolveComponent("bar-chart")!

  return (_ctx.stats)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_line_chart, {
            data: _ctx.timeCounterChart,
            options: _ctx.timeCounterChartOptions
          }, null, 8, ["data", "options"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_bar_chart, {
            data: _ctx.waitTimesChart,
            options: _ctx.waitTimesChartOptions
          }, null, 8, ["data", "options"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_line_chart, {
            data: _ctx.dailyGenerationsChart,
            options: _ctx.dailyGenerationsChartOptions
          }, null, 8, ["data", "options"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_line_chart, {
            data: _ctx.dailyCumulativeGenerationsChart,
            options: _ctx.dailyCumulativeGenerationsChartOptions
          }, null, 8, ["data", "options"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_bar_chart, {
            data: _ctx.queryTimesChart,
            options: _ctx.queryTimesChartOptions
          }, null, 8, ["data", "options"])
        ])
      ]))
    : _createCommentVNode("", true)
}