<template>
	<svg
		width="15"
		height="15"
		viewBox="0 0 15 15"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14.4198 9.0001C14.3477 8.9278 14.2572 8.87651 14.1581 8.85173C14.059 8.82696 13.955 8.82963 13.8573 8.85948C12.7848 9.18373 11.6443 9.21092 10.5575 8.93814C9.47074 8.66537 8.4783 8.10285 7.68598 7.31053C6.89367 6.51821 6.33115 5.52578 6.05837 4.43898C5.7856 3.35218 5.81278 2.21173 6.13704 1.13917C6.16713 1.04143 6.17001 0.937345 6.14537 0.838097C6.12073 0.738849 6.0695 0.648195 5.99719 0.575885C5.92488 0.503576 5.83423 0.452346 5.73498 0.427706C5.63573 0.403066 5.53164 0.405947 5.43391 0.436041C3.95104 0.890291 2.64921 1.80066 1.71368 3.0376C0.89553 4.12383 0.396481 5.41668 0.272598 6.7709C0.148714 8.12512 0.404905 9.48705 1.01239 10.7037C1.61987 11.9203 2.55457 12.9435 3.71149 13.6582C4.86841 14.3729 6.20169 14.7508 7.56157 14.7496C9.14806 14.7544 10.6923 14.2387 11.9575 13.2814C13.1944 12.3459 14.1048 11.0441 14.5591 9.5612C14.5888 9.46383 14.5916 9.36021 14.5671 9.2614C14.5425 9.16258 14.4917 9.07227 14.4198 9.0001ZM11.2811 12.3828C10.0897 13.2802 8.61421 13.717 7.1263 13.6128C5.63838 13.5086 4.23818 12.8705 3.18345 11.8158C2.12871 10.7612 1.49045 9.36104 1.38614 7.87313C1.28184 6.38522 1.71851 4.90972 2.61579 3.71823C3.20037 2.94624 3.95613 2.32045 4.8236 1.8901C4.77418 2.2369 4.74928 2.58675 4.74907 2.93706C4.75112 4.87582 5.52219 6.7346 6.89311 8.10551C8.26403 9.47643 10.1228 10.2475 12.0616 10.2496C12.4126 10.2494 12.7631 10.2245 13.1106 10.175C12.6799 11.0426 12.0536 11.7984 11.2811 12.3828Z"
			fill="currentColor"
		/>
	</svg>
</template>
