<template>
	<svg
		width="79"
		height="48"
		viewBox="0 0 79 48"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g opacity="0.1">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M4.01633 2.00818C4.01633 0.912813 3.10353 0 2.00816 0C0.912802 0 0 0.912813 0 2.00818C0 3.10354 0.912802 4.01632 2.00816 4.01632C3.10353 4.01632 4.01633 3.10354 4.01633 2.00818Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M16.2478 2.00818C16.2478 3.10354 15.335 4.01632 14.2396 4.01632C13.1442 4.01632 12.2314 3.10354 12.2314 2.00818C12.2314 0.912813 13.1442 0 14.2396 0C15.335 0.18256 16.2478 0.912813 16.2478 2.00818Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M28.6618 2.00818C28.6618 0.912813 27.749 0 26.6537 0C25.5583 0 24.6455 0.912813 24.6455 2.00818C24.6455 3.10354 25.5583 4.01632 26.6537 4.01632C27.749 4.01632 28.6618 3.10354 28.6618 2.00818Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M41.0759 2.00818C41.0759 0.912813 40.1631 0 39.0677 0C37.9724 0 37.0596 0.912813 37.0596 2.00818C37.0596 3.10354 37.9724 4.01632 39.0677 4.01632C40.1631 4.01632 41.0759 3.10354 41.0759 2.00818Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M53.3073 2.00818C53.3073 0.912813 52.3945 0 51.2992 0C50.2038 0 49.291 0.912813 49.291 2.00818C49.291 3.10354 50.2038 4.01632 51.2992 4.01632C52.3945 4.01632 53.3073 3.10354 53.3073 2.00818Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M65.7214 2.00818C65.7214 0.912813 64.8086 0 63.7132 0C62.6179 0 61.7051 0.912813 61.7051 2.00818C61.7051 3.10354 62.6179 4.01632 63.7132 4.01632C64.8086 4.01632 65.7214 3.10354 65.7214 2.00818Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M78.1355 2.00818C78.1355 0.912813 77.2227 0 76.1273 0C75.0319 0 74.1191 0.912813 74.1191 2.00818C74.1191 3.10354 75.0319 4.01632 76.1273 4.01632C77.2227 4.01632 78.1355 3.10354 78.1355 2.00818Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M4.01633 12.7796C4.01633 11.6843 3.10353 10.7715 2.00816 10.7715C0.912802 10.7715 0 11.6843 0 12.7796C0 13.875 0.912802 14.7878 2.00816 14.7878C3.10353 14.7878 4.01633 13.875 4.01633 12.7796Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M16.2478 12.7796C16.2478 13.875 15.335 14.7878 14.2396 14.7878C13.1442 14.7878 12.2314 13.875 12.2314 12.7796C12.2314 11.6843 13.1442 10.7715 14.2396 10.7715C15.335 10.954 16.2478 11.6843 16.2478 12.7796Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M28.6618 12.7796C28.6618 11.6843 27.749 10.7715 26.6537 10.7715C25.5583 10.7715 24.6455 11.6843 24.6455 12.7796C24.6455 13.875 25.5583 14.7878 26.6537 14.7878C27.749 14.7878 28.6618 13.875 28.6618 12.7796Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M41.0759 12.7796C41.0759 11.6843 40.1631 10.7715 39.0677 10.7715C37.9724 10.7715 37.0596 11.6843 37.0596 12.7796C37.0596 13.875 37.9724 14.7878 39.0677 14.7878C40.1631 14.7878 41.0759 13.875 41.0759 12.7796Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M53.3073 12.7796C53.3073 11.6843 52.3945 10.7715 51.2992 10.7715C50.2038 10.7715 49.291 11.6843 49.291 12.7796C49.291 13.875 50.2038 14.7878 51.2992 14.7878C52.3945 14.7878 53.3073 13.875 53.3073 12.7796Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M65.7214 12.7796C65.7214 11.6843 64.8086 10.7715 63.7132 10.7715C62.6179 10.7715 61.7051 11.6843 61.7051 12.7796C61.7051 13.875 62.6179 14.7878 63.7132 14.7878C64.8086 14.7878 65.7214 13.875 65.7214 12.7796Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M78.1355 12.7796C78.1355 11.6843 77.2227 10.7715 76.1273 10.7715C75.0319 10.7715 74.1191 11.6843 74.1191 12.7796C74.1191 13.875 75.0319 14.7878 76.1273 14.7878C77.2227 14.7878 78.1355 13.875 78.1355 12.7796Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M4.01633 23.5502C4.01633 22.4548 3.10353 21.542 2.00816 21.542C0.912802 21.542 0 22.4548 0 23.5502C0 24.6455 0.912802 25.5583 2.00816 25.5583C3.10353 25.5583 4.01633 24.6455 4.01633 23.5502Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M16.2478 23.5502C16.2478 24.6455 15.335 25.5583 14.2396 25.5583C13.1442 25.5583 12.2314 24.6455 12.2314 23.5502C12.2314 22.4548 13.1442 21.542 14.2396 21.542C15.335 21.542 16.2478 22.4548 16.2478 23.5502Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M28.6618 23.5502C28.6618 22.4548 27.749 21.542 26.6537 21.542C25.5583 21.542 24.6455 22.4548 24.6455 23.5502C24.6455 24.6455 25.5583 25.5583 26.6537 25.5583C27.749 25.5583 28.6618 24.6455 28.6618 23.5502Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M41.0759 23.5502C41.0759 22.4548 40.1631 21.542 39.0677 21.542C37.9724 21.542 37.0596 22.4548 37.0596 23.5502C37.0596 24.6455 37.9724 25.5583 39.0677 25.5583C40.1631 25.5583 41.0759 24.6455 41.0759 23.5502Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M53.3073 23.5502C53.3073 22.4548 52.3945 21.542 51.2992 21.542C50.2038 21.542 49.291 22.4548 49.291 23.5502C49.291 24.6455 50.2038 25.5583 51.2992 25.5583C52.3945 25.5583 53.3073 24.6455 53.3073 23.5502Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M65.7214 23.5502C65.7214 22.4548 64.8086 21.542 63.7132 21.542C62.6179 21.542 61.7051 22.4548 61.7051 23.5502C61.7051 24.6455 62.6179 25.5583 63.7132 25.5583C64.8086 25.5583 65.7214 24.6455 65.7214 23.5502Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M78.1355 23.5502C78.1355 22.4548 77.2227 21.542 76.1273 21.542C75.0319 21.542 74.1191 22.4548 74.1191 23.5502C74.1191 24.6455 75.0319 25.5583 76.1273 25.5583C77.2227 25.5583 78.1355 24.6455 78.1355 23.5502Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M4.01633 34.3217C4.01633 33.2263 3.10353 32.3135 2.00816 32.3135C0.912802 32.3135 0 33.2263 0 34.3217C0 35.417 0.912802 36.3298 2.00816 36.3298C3.10353 36.3298 4.01633 35.417 4.01633 34.3217Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M16.2478 34.3217C16.2478 35.417 15.335 36.3298 14.2396 36.3298C13.1442 36.3298 12.2314 35.417 12.2314 34.3217C12.2314 33.2263 13.1442 32.3135 14.2396 32.3135C15.335 32.3135 16.2478 33.2263 16.2478 34.3217Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M28.6618 34.3217C28.6618 33.2263 27.749 32.3135 26.6537 32.3135C25.5583 32.3135 24.6455 33.2263 24.6455 34.3217C24.6455 35.417 25.5583 36.3298 26.6537 36.3298C27.749 36.3298 28.6618 35.417 28.6618 34.3217Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M41.0759 34.3217C41.0759 33.2263 40.1631 32.3135 39.0677 32.3135C37.9724 32.3135 37.0596 33.2263 37.0596 34.3217C37.0596 35.417 37.9724 36.3298 39.0677 36.3298C40.1631 36.3298 41.0759 35.417 41.0759 34.3217Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M53.3073 34.3217C53.3073 33.2263 52.3945 32.3135 51.2992 32.3135C50.2038 32.3135 49.291 33.2263 49.291 34.3217C49.291 35.417 50.2038 36.3298 51.2992 36.3298C52.3945 36.3298 53.3073 35.417 53.3073 34.3217Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M65.7214 34.3217C65.7214 33.2263 64.8086 32.3135 63.7132 32.3135C62.6179 32.3135 61.7051 33.2263 61.7051 34.3217C61.7051 35.417 62.6179 36.3298 63.7132 36.3298C64.8086 36.3298 65.7214 35.417 65.7214 34.3217Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M78.1355 34.3217C78.1355 33.2263 77.2227 32.3135 76.1273 32.3135C75.0319 32.3135 74.1191 33.2263 74.1191 34.3217C74.1191 35.417 75.0319 36.3298 76.1273 36.3298C77.2227 36.3298 78.1355 35.417 78.1355 34.3217Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M4.01633 45.0921C4.01633 43.9968 3.10353 43.084 2.00816 43.084C0.912802 43.084 0 43.9968 0 45.0921C0 46.1875 0.912802 47.1003 2.00816 47.1003C3.10353 47.1003 4.01633 46.1875 4.01633 45.0921Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M16.2478 45.0921C16.2478 46.1875 15.335 47.1003 14.2396 47.1003C13.1442 47.1003 12.2314 46.1875 12.2314 45.0921C12.2314 43.9968 13.1442 43.084 14.2396 43.084C15.335 43.084 16.2478 43.9968 16.2478 45.0921Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M28.6618 45.0921C28.6618 43.9968 27.749 43.084 26.6537 43.084C25.5583 43.084 24.6455 43.9968 24.6455 45.0921C24.6455 46.1875 25.5583 47.1003 26.6537 47.1003C27.749 47.1003 28.6618 46.1875 28.6618 45.0921Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M41.0759 45.0921C41.0759 43.9968 40.1631 43.084 39.0677 43.084C37.9724 43.084 37.0596 43.9968 37.0596 45.0921C37.0596 46.1875 37.9724 47.1003 39.0677 47.1003C40.1631 47.1003 41.0759 46.1875 41.0759 45.0921Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M53.3073 45.0921C53.3073 43.9968 52.3945 43.084 51.2992 43.084C50.2038 43.084 49.291 43.9968 49.291 45.0921C49.291 46.1875 50.2038 47.1003 51.2992 47.1003C52.3945 47.1003 53.3073 46.1875 53.3073 45.0921Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M65.7214 45.0921C65.7214 43.9968 64.8086 43.084 63.7132 43.084C62.6179 43.084 61.7051 43.9968 61.7051 45.0921C61.7051 46.1875 62.6179 47.1003 63.7132 47.1003C64.8086 47.1003 65.7214 46.1875 65.7214 45.0921Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M78.1355 45.0921C78.1355 43.9968 77.2227 43.084 76.1273 43.084C75.0319 43.084 74.1191 43.9968 74.1191 45.0921C74.1191 46.1875 75.0319 47.1003 76.1273 47.1003C77.2227 47.1003 78.1355 46.1875 78.1355 45.0921Z"
				fill="white"
			/>
		</g>
	</svg>
</template>
