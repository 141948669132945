<template>
	<div class="terms-container">
		<div class="terms">
			<h1>Prodia Labs Terms of Service</h1>
			Last Updated January 26, 2022 <br />
			<br />
			<h2>Introduction, Overview and Agreement</h2>
			<p>
				These terms of service (and any other terms or information
				incorporated into them by reference) (collectively the “Terms”)
				govern your access and use of our products and services
				(described below) and any related content provided to you
				through our website located at
				<a href="https://prodia.com">https://prodia.com</a>,
				<a href="https://app.prodia.com">https://app.prodia.com,</a>
				API, mobile applications and related technologies (“Mobile Apps”
				and collectively with our website and API, including any updated
				or new features, functionality and technology, the “Services”).
			</p>
			<br />
			<p>
				The Services are provided by Prodia Labs Inc. (“Prodia,” “we,”
				or “us”) to provide generative tools for our users to express
				their creativity to produce, share or otherwise make available
				video, images, information, data, text, software, music, sound,
				photographs, graphics, messages, and other materials content
				(“Content”). The Services use an artificial intelligence (“AI”)
				tool to generate this Content. This use of AI is relatively new
				and still evolving. As a result, while we have taken — and
				continue to take — efforts to preclude your creation of content,
				we cannot guarantee the suitability or appropriateness of the
				resulting Content (including all art, music, video, etc.) you
				generate. You are solely responsible for your use of the
				Services, including your generation of Content, and the
				consequences of your Content Sharing (as defined below).
			</p>
			<br />
			<p>
				PLEASE READ THESE TERMS CAREFULLY, AS THEY CONTAIN AN AGREEMENT
				TO ARBITRATE AND OTHER IMPORTANT INFORMATION REGARDING YOUR
				LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS. THE AGREEMENT TO
				ARBITRATE REQUIRES (WITH LIMITED EXCEPTION) THAT YOU SUBMIT
				CLAIMS YOU HAVE AGAINST US TO BINDING AND FINAL ARBITRATION, AND
				FURTHER (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST
				PRODIA ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS
				MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, (2)
				YOU WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY,
				INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS, AND
				(3) YOU MAY NOT BE ABLE TO HAVE ANY CLAIMS YOU HAVE AGAINST US
				RESOLVED BY A JURY OR IN A COURT OF LAW.
			</p>
			<br />
			<h2>Your Information</h2>
			<p>
				For information concerning how and why we might collect, store,
				use, and/or share your personal information when you use the
				Services, including when you register to set up a membership
				account, please read and review our Privacy Policy.
			</p>
			<br />
			<h2>Community Guidelines</h2>
			<p>
				As a valued member of our user community, the contribution of
				your near-boundless creativity plays an important part in
				creating a dream-worthy environment to produce incredible
				Content. We like to say “near-boundless” because your
				contributions must be safe, legal, and in accordance with these
				Terms. We want to be very clear about what we expect from you,
				and how we may respond if you do not meet our expectations.
				Before posting, publishing, or otherwise making available
				Content with others (collectively, “Content Sharing”), you must
				give careful consideration to the potential physical, emotional,
				mental, financial, and reputational harm that it may have on
				others. Content that may be acceptable, harmless, satirical,
				artistic, or humorous to you might be perceived as harassing,
				threatening, intimidating, upsetting, disturbing, embarrassing,
				distressing, or hateful to someone else. These Community
				Guidelines apply to your Content and use of the Services,
				including any Content Sharing.
			</p>
			<br />
			<p>
				ANY VIOLATION OF THESE TERMS MAY RESULT IN THE SUSPENSION OR
				TERMINATION BY US, IN OUR SOLE DISCRETION, OF YOUR ACCESS TO AND
				USE OF THE SERVICES (INCLUDING A PERMANENT BAN AND YOU WILL
				FORFEIT ANY REMAINING CREDITS AND FEES PAID FOR THE SERVICES).
				WE MAY CHANGE THE SERVICES IN OUR SOLE DISCRETION. WE WILL
				ENDEAVOR TO PROVIDE REASONABLE NOTICE OF ANY SUSPENSION,
				TERMINATION, OR CHANGE TO YOU, BUT MAY DO SO WITHOUT NOTICE IF
				IN OUR SOLE JUDGMENT, WE NEED TO MAKE CHANGES TO COMPLY WITH
				LAW, PROTECT OR ENFORCE LEGAL RIGHTS, AVOID OR MITIGATE MISUSE
				OF THE SERVICES OR ADVERSE LEGAL OR REGULATORY IMPACTS ON US, OR
				OTHERWISE TO ADDRESS OR PREVENT AN EMERGENCY. IF WE DISABLE YOUR
				ACCOUNT FOR A VIOLATION OF THE TERMS OR ANY OTHER REASON, YOU
				MAY NOT CREATE ANOTHER ACCOUNT WITHOUT OUR EXPRESS PERMISSION,
				WHICH WE MAY WITHHOLD IN OUR SOLE DISCRETION. IF WE LET YOU
				CREATE A NEW ACCOUNT, IT DOES NOT IMPLY THAT OUR PRIOR DECISION
				TO DISABLE YOUR ACCOUNT WAS WRONG OR WITHOUT CAUSE. SUSPENSION
				OR TERMINATION OF YOUR ACCESS TO AND USE OF THE SERVICES WILL
				NOT TERMINATE PRODIA’S RIGHTS TO YOUR CONTENT.
			</p>
			<br />
			<p>
				Without limiting the foregoing or any other rights Prodia might
				have, you acknowledge and agree that Prodia may preserve your
				Content and may also disclose your Content if required to do so
				by law or in the good faith belief that such preservation or
				disclosure is reasonably necessary to: (a) comply with legal
				process, applicable laws, or government requests; (b) enforce
				these Terms; (c) respond to claims that any content violates the
				rights of third parties; or (d) protect the rights, property, or
				personal safety of Prodia, its users, or the public. You
				understand that the technical processing and transmission of the
				Service, including your Content, may involve (i) transmissions
				over various networks; and (ii) changes to conform and adapt to
				technical requirements of connecting networks or devices.
			</p>
			<br />
			<h2>Prohibited Uses</h2>
			<p>
				We want you to use the Services to express yourself and to
				develop Content that you find interesting, but not at the
				expense of the safety and well-being of others. Accordingly, you
				may not, nor may you assist any other person to:
			</p>
			<br />
			<li>
				Violate these Terms or other policies applicable to the
				Services;
			</li>
			<br />
			<li>
				Include sensitive personal information (such as phone numbers,
				residential addresses, health information, social security
				numbers, driver’s license numbers, or other account numbers)
				about yourself or any other person;
			</li>
			<br />
			<li>
				Violate the privacy, publicity, or other rights of any third
				party;
			</li>
			<br />
			<li>
				Further or promote criminal activity or enterprise or provide
				instructional information about illegal activities;
			</li>
			<br />
			<li>
				Generate any Content, engage in any Content Sharing or otherwise
				use the Services in any manner that a reasonable person could
				find obscene, lewd, lascivious, offensive, pornographic,
				indecent, vulgar, prurient, excessively violent, or to be a
				glorification or promotion of violence or a celebration of the
				suffering or humiliation of any person or class of people
				(whether living or deceased) (including visible genitalia, bare
				breasts, fully-nude buttocks, depictions of suicide or explicit
				sexual activity, fetishistic content, bodily fluids, bestiality,
				or imagery that shows violent death or acts of torture);
			</li>
			<br />
			<li>
				Generate any Content, engage in any Content Sharing or otherwise
				use the Services in any manner that has any risk or possibility
				of exploiting, harming, or endangering the health or well-being
				of children or other minors (“Children”), such as images of
				Children in sexualized costumes, poses, or a sexual fetishistic
				context, or which identifies, directly or indirectly, alleged
				victims of child sexual exploitation, or for the purpose of
				exploiting, harming or attempting to exploit or harm Children in
				any way;
			</li>
			<br />
			<li>
				Generate any Content, engage in any Content Sharing or otherwise
				use the Services in any manner that may exploit any
				vulnerabilities, offend human dignity or may otherwise be
				defamatory, libelous, harassing, threatening, embarrassing,
				disparaging, distressing, hateful — racially, ethnically, or
				otherwise — to a person or class of people, or which may be
				discriminatory towards a person’s or class of people’s race,
				religion, color, age, ethnicity, national origin, disability,
				physical, or mental characteristics, sexual orientation, gender
				expression, gender identity, family status, medical or genetic
				condition, personality characteristics, or physical appearance,
				including through the material distortion of the behavior of any
				such person or class of people in a manner that causes or is
				likely to cause that person or class of people physical or
				psychological harm;
			</li>
			<br />
			<li>
				Use the Services or any Content to stalk, harass, abuse, mock,
				ridicule, intimidate, disparage, defame, threaten, defraud, or
				otherwise mistreat or harm any person or class of people;
			</li>
			<br />
			<li>
				Generate any Content, engage in any Content Sharing or otherwise
				use the Services in any manner that is intentionally misleading,
				false, or otherwise inappropriate or with the purpose of harming
				others, regardless of whether the Content or its dissemination
				is unlawful;
			</li>
			<br />
			<li>
				Upload any materials that: (i) infringe any intellectual
				property or other proprietary rights of any party; (ii) you do
				not have a right to upload under any law or under any
				contractual or fiduciary relationship;
			</li>
			<br />
			<li>
				Upload any material, program, or software that contains any
				virus, worm, spyware, Trojan horse or other program or code
				designed to interrupt, destroy or limit the functionality of the
				Services, launching a denial of service attack, or in any other
				way attempting to interfere with the functioning and
				availability of the Services;
			</li>
			<br />
			<li>
				Interfere with or disrupt the Services or servers or networks
				connected to the Services;
			</li>
			<br />
			<li>
				Access or otherwise obtain any materials or information through
				any means not intentionally made available or provided for by
				Prodia to you through the Services;
			</li>
			<br />
			<li>
				Use the Services in any way that violates any applicable
				national, federal, state, local or international law or
				regulation; or
			</li>
			<br />
			<li>Attempt to do any of the above.</li>
			<br />
			<p>
				The Services may block or blur certain Content if we find (in
				our sole judgment) such Content too graphic, offensive,
				insensitive, or hurtful, even in circumstances where you may not
				have had any ill intent or where the Content does not otherwise
				violate the prohibitions described above. We may also block or
				remove Content that we otherwise find objectionable or that we
				believe in our reasonable judgment may expose us or others to
				any harm or liability.
			</p>
			<br />
			<h2>Access and Use of the Service</h2>
			<p>
				Your Registration Obligations: You may be required to register
				with Prodia or provide information about yourself (e.g., name
				and email address) in order to access and use certain features
				of the Services. If you choose to register for the Services, you
				agree to provide and maintain true, accurate, current, and
				complete information about yourself as prompted by the Service’s
				registration form. Registration data and certain other
				information about you are governed by our Privacy Policy. If you
				are under 13 years of age, you are not authorized to use the
				Services, with or without registering. In addition, if you are
				under 18 years old, you may use the Services, with or without
				registering, only with the approval of your parent or guardian.
			</p>
			<br />
			<p>
				Member Account and Security: You are responsible for maintaining
				the confidentiality of your account details, if any, and are
				fully responsible for any and all activities that occur under
				your account. You agree to immediately notify Prodia of any
				unauthorized use of your account or any other breach of
				security. Prodia will not be liable for any loss or damage
				arising from your failure to comply with this paragraph.
			</p>
			<br />
			<p>
				Modifications to Services: Prodia reserves the right to modify
				or discontinue, temporarily or permanently, the Services (or any
				part thereof) with or without notice. You agree that Prodia will
				not be liable to you or to any third party for any modification,
				suspension or discontinuance of the Services.
			</p>
			<br />
			<p>
				General Practices Regarding Use and Storage: You acknowledge
				that Prodia may establish general practices and limits
				concerning use of the Services, including the maximum period of
				time that data or other content will be retained by the Services
				and the maximum storage space that will be allotted on Prodia’s
				or its third-party service providers’ servers on your behalf.
				You agree that Prodia has no responsibility or liability for the
				deletion or failure to store any data or other content
				generated, maintained or uploaded by the Services. You
				acknowledge that Prodia reserves the right to terminate accounts
				that are inactive for an extended period of time. You further
				acknowledge that Prodia reserves the right to change these
				general practices and limits at any time, in its sole
				discretion, with or without notice.
			</p>
			<br />
			<p>
				API Access and Use; Restrictions: As a part of the Services,
				Prodia may make available to you an application program
				interface and related materials (collectively, the “API”).
				Subject to your compliance with these Terms, Prodia hereby
				grants you a non-exclusive, non-sublicensable, non-transferable
				license to download and use the API solely to receive the data
				made available by Prodia through the API (“Data”). Prodia will
				use commercially reasonable efforts to provide the API and Data
				as contemplated hereunder. If you identify any errors with
				respect to the access and use of the API and Data, you must
				immediately notify Prodia. In addition to all other use
				restrictions set forth herein, you may not, nor may you assist
				any other person to: (a) install, download, distribute or
				otherwise use the API or the Data for any purpose other than as
				expressly set forth in this Section; (b) use the API or the Data
				for any illegal purposes; (c) repackage the Data in any exported
				or application programming interface form (including without
				limitation CSV, XML or JSON); (d) use the API or the Data in any
				manner that competes with Prodia; (e) use the API or the Data in
				any manner that violates the rights of any person, including but
				not limited to intellectual property rights, rights of privacy
				or rights of publicity; (f) copy, rent, lease, sell, sublicense,
				disassemble, reverse engineer or decompile (except to the
				limited extent expressly authorized by applicable statutory
				law), modify or alter any part of the API or Data; or (g)
				distribute or otherwise disclose the Data to any third parties
				on a standalone basis. Prodia reserves the right to limit the
				number and/or frequency of API requests in its sole discretion.
			</p>
			<br />
			<h2>Ownership of Content; Prodia Use of Content</h2>
			<p>
				Services Content: You acknowledge and agree that the Services
				may contain content or features (“Services Content”) that are
				protected by copyright, patent, trademark, trade secret, or
				other proprietary rights and laws. Except as expressly
				authorized by Prodia, you agree not to modify, copy, frame,
				scrape, rent, lease, loan, sell, distribute, or create
				derivative works based on the Service or the Services Content,
				in whole or in part, except that the foregoing does not apply to
				your own Content that you upload to or make available through
				the Services in accordance with these Terms. Any use of the
				Services or the Services Content other than as specifically
				authorized herein is strictly prohibited.
			</p>
			<br />
			<p>
				Trademarks: The Prodia name and logos are trademarks and service
				marks of Prodia (collectively the “Prodia Trademarks”). Other
				company, product, and service names and logos used and displayed
				via the Services may be trademarks or service marks of their
				respective owners who may or may not endorse or be affiliated
				with or connected to Prodia. Nothing in these Terms or the
				Services should be construed as granting, by implication,
				estoppel, or otherwise, any license or right to use any of
				Prodia Trademarks displayed on the Services, without our prior
				written permission in each instance. All goodwill generated from
				the use of Prodia Trademarks will inure to our exclusive
				benefit.
			</p>
			<br />
			<p>
				Third-Party Material: Under no circumstances will Prodia be
				liable in any way for any content or materials of any third
				parties (including users), including for any errors or omissions
				in any content, or for any loss or damage of any kind incurred
				as a result of the use of any such content. You acknowledge that
				Prodia does not pre-screen content, but that Prodia and its
				designees will have the right (but not the obligation) in their
				sole discretion to refuse or remove any content that is
				available via the Services. Without limiting
			</p>
			<br />
			<p>
				the foregoing, Prodia and its designees will have the right to
				remove any content that violates these Terms or is deemed by
				Prodia, in its sole discretion, to be otherwise objectionable.
				You agree that you must evaluate, and bear all risks associated
				with, the use of any content, including any reliance on the
				accuracy, completeness, or usefulness of such content.
			</p>
			<br />
			<p>
				Your Content:<br />

				You represent and warrant that you own all right, title and
				interest in and to your Content, including all copyrights and
				rights of publicity contained therein. You hereby grant Prodia
				and its affiliated companies, successors, and assigns a
				non-exclusive, worldwide, royalty-free, fully paid-up,
				transferable, sublicensable (directly and indirectly through
				multiple tiers), perpetual, and irrevocable license to copy,
				display, upload, perform, distribute, store, modify, and
				otherwise use your Content in connection with the operation of
				the Services and the promotion, advertising or marketing of the
				foregoing in any form, medium or technology now known or later
				developed. You assume all risk associated with your Content and
				the transmission of your Content, and you have sole
				responsibility for the accuracy, quality, legality and
				appropriateness of your Content.
			</p>
			<br />
			<p>
				Your Generated Content: <br />
				Any Content that you generate via the Services is subject to the
				terms, conditions, and use restrictions in the Creative ML
				OpenRAIL-M license (available at
				<a
					href="https://huggingface.co/spaces/CompVis/stable-diffusion-license"
					>(https://huggingface.co/spaces/CompVis/stable-diffusion-license)</a
				>. You must read this license thoroughly before generating
				Content on the Services.
			</p>
			<br />
			<p>
				Usage Data: You hereby authorize Prodia and its third-party
				service providers to derive statistical and usage data relating
				to your use of the Services (“Usage Data”). We may use Usage
				Data for any purpose in accordance with applicable law and our
				Privacy Policy.
			</p>
			<br />
			<p>
				Submissions: Any questions, comments, suggestions, ideas,
				feedback, reviews, or other information about the Services
				(“Submissions”), provided by you to Prodia are non-confidential
				and Prodia will be entitled to the unrestricted use and
				dissemination of these Submissions for any purpose, commercial
				or otherwise, without acknowledgment, attribution, or
				compensation to you.
			</p>
			<br />
			<h2>Copyright Complaints</h2>
			<p>
				Prodia respects the intellectual property of others, and we ask
				our users to do the same. If you believe that your work has been
				copied in a way that constitutes copyright infringement, or that
				your intellectual property rights have been otherwise violated,
				you should notify Prodia of your infringement claim in
				accordance with the procedure set forth below.
			</p>
			<br />
			<p>
				Prodia will process and investigate notices of alleged
				infringement and will take appropriate actions under the Digital
				Millennium Copyright Act (“DMCA”) and other applicable
				intellectual property laws with respect to any alleged or actual
				infringement. A notification of claimed copyright infringement
				should be emailed to Prodia’s Copyright Agent at dmca@prodia.com
				(Subject line: “DMCA Takedown Request”). You may also contact
				the Copyright Agent by mail or facsimile at:
			</p>
			<br />
			<p>
				<b>If by mali: </b><br />
				1155 Mount Vernon HWY NE, <br />
				Suite 800 Atlanta, <br />
				GA 30338 <br />
				<br />
				<b>If by fax: </b><br />1 (470) 994-0478
			</p>
			<br />
			<p>
				To be effective, the notification must be in writing and contain
				the following information:
			</p>
			<br />
			<li>
				a physical or electronic signature of a person authorized to act
				on behalf of the owner of the copyright or other intellectual
				property interest that is allegedly infringed;
			</li>
			<br />
			<li>
				identification of the copyrighted work or other intellectual
				property that you claim has been infringed, or, if multiple
				copyrighted works or other intellectual property are covered by
				a single notification, a representative list of such works or
				other intellectual property;
			</li>
			<br />
			<li>
				identification of the content that is claimed to be infringing
				or to be the subject of infringing activity, and where the
				content that you claim is infringing is located on the Service,
				with enough detail that we may find it on the Service;
			</li>
			<br />
			<li>your address, telephone number, and email address;</li>
			<br />
			<li>
				a statement by you that you have a good faith belief that the
				disputed use is not authorized by the copyright or intellectual
				property owner, its agent, or the law; and
			</li>
			<br />
			<li>
				a statement by you that the information in your notice is
				accurate and, under penalty of perjury, that you are the
				copyright or intellectual property owner or are authorized to
				act on the behalf of the owner of the copyright or intellectual
				property that is allegedly infringed.
			</li>
			<br />
			<p>
				Counter-Notice: If you believe that your Content that was
				removed (or to which access was disabled) is not infringing, or
				that you have the authorization from the copyright owner, the
				copyright owner’s agent, or pursuant to the law, to upload and
				use the content in your Content, you may send a written
				counter-notice containing the following information to the
				Copyright Agent:
			</p>
			<br />
			<li>your physical or electronic signature;</li>
			<br />
			<li>
				identification of the content that has been removed or to which
				access has been disabled and the location at which the content
				appeared before it was removed or disabled;
			</li>
			<br />
			<li>
				a statement by you, made under penalty of perjury, that you have
				a good faith belief that the content was removed or disabled as
				a result of mistake or a misidentification of the content to be
				removed or disabled; and
			</li>
			<br />
			<li>
				your name, address, telephone number, and email address, a
				statement that you consent to the jurisdiction of the federal
				court located within the Northern District of Georgia and a
				statement that you will accept service of process from the
				person who provided notification of the alleged infringement.
			</li>
			<br />
			<p>
				If a counter-notice is received by the Copyright Agent, Prodia
				will send a copy of the counter-notice to the original
				complaining party informing them that Prodia may replace the
				removed content or cease disabling it within ten (10) business
				days. Unless the owner of the applicable copyrighted work or
				other intellectual property files an action seeking a court
				order against Prodia or the user, the removed content may be
				replaced, or access to it restored, within ten (10) to fourteen
				(14) business days or more after receipt of the counter-notice,
				at our sole discretion.
			</p>
			<br />
			<p>
				Repeat Infringer Policy: In accordance with the DMCA and other
				applicable law, Prodia has adopted a policy of terminating, in
				appropriate circumstances and at Prodia’s sole discretion, the
				accounts of users who are deemed to be repeat infringers. Prodia
				may also at its sole discretion limit access to the Services
				and/or terminate the accounts of any users who infringe any
				intellectual property rights of others, whether or not there is
				any repeat infringement.
			</p>
			<br />
			<h2>Mobile Services and Software</h2>
			<p>
				Mobile Services: The Services include certain services that are
				available via a mobile device, including (i) the ability to
				upload content to the Services via a mobile device, (ii) the
				ability to browse the Services and our website from a mobile
				device, and (iii) the ability to access certain features and
				content through Mobile Apps (collectively, the “Mobile
				Services”). To the extent you access the Services through a
				mobile device, your wireless service carrier’s standard charges,
				data rates, and other fees may apply. In addition, downloading,
				installing, or using certain Mobile Services may be prohibited
				or restricted by your carrier, and not all Mobile Services may
				work with all carriers or devices. By using the Mobile Services,
				you agree that we may communicate with you regarding Prodia and
				other entities by SMS, MMS, text message, or other electronic
				means to your mobile device and that certain information about
				your usage of the Mobile Services may be communicated to us. In
				the event you change or deactivate your mobile telephone number,
				you agree to promptly notify Prodia to ensure that your messages
				are not sent to the person that acquires your old number.
			</p>
			<br />
			<p>
				Mobile App License: Subject to these Terms, Prodia hereby grants
				to you a limited, revocable, non-exclusive, non-transferable,
				non-sublicensable license to (a) install the Mobile App on one
				mobile device and (b) use the Mobile App for your own personal
				use solely to access and use the Services. For clarity, the
				foregoing is not intended to prohibit you from installing the
				Mobile App on another device on which you also agreed to these
				Terms. Each instance of these Terms that you agree to in
				connection with downloading a Mobile App grants you the
				aforementioned rights in connection with the installation and
				use of the Mobile App on one device.
			</p>
			<br />
			<p>
				Ownership; Restrictions: The technology and software underlying
				the Services or distributed in connection therewith are the
				property of Prodia, its affiliates, and its licensors (including
				the Mobile Apps, the “Software”). You agree not to copy, modify,
				create a derivative work of, reverse engineer, reverse assemble,
				or otherwise attempt to discover any source code, sell, assign,
				sublicense, or otherwise transfer any right in the Software. Any
				rights not expressly granted herein are reserved by Prodia.
			</p>
			<br />
			<p>
				Special Notice for International Use; Export Controls: Prodia is
				headquartered in the United States. If you access or use the
				Services from outside of the United States, you do so at your
				own risk. Whether inside or outside of the United States, you
				are solely responsible for ensuring compliance with the laws of
				your specific jurisdiction. Software available in connection
				with the Services and the transmission of applicable data, if
				any, is subject to United States export controls. No Software
				may be downloaded from the Services or otherwise exported or
				re-exported in violation of U.S. export laws. Downloading or
				using the Software is at your sole risk.
			</p>
			<br />
			<p>
				Third-Party Distribution Channels: Prodia offers Software that
				may be made available through the Apple App Store, the Google
				Play Store, or other distribution channels (“Distribution
				Channels”). If you obtain such Software through a Distribution
				Channel, you may be subject to additional terms of the
				Distribution Channel. These Terms are between you and us only,
				and not with the Distribution Channel. To the extent that you
				utilize any other third-party products and services in
				connection with your use of the Services, you agree to comply
				with all applicable terms of any agreement for such third-party
				products and services.
			</p>
			<br />
			<p>
				Apple-Enabled Software: With respect to Mobile Apps that are
				made available for your use in connection with an Apple-branded
				product (the, “Apple-Enabled Software”), in addition to the
				other terms and conditions set forth in these Terms, the
				following terms and conditions apply:
			</p>
			<br />
			<li>
				Prodia and you acknowledge that these Terms are concluded
				between Prodia and you only, and not with Apple Inc. (“Apple”),
				and that as between Prodia and Apple, Prodia, not Apple, is
				solely responsible for the Apple-Enabled Software and the
				content thereof.
			</li>
			<br />
			<li>
				You may not use the Apple-Enabled Software in any manner that is
				in violation of or inconsistent with the Usage Rules set forth
				for Apple-Enabled Software in, or otherwise be in conflict with,
				the Apple Media Services Terms and Conditions.
			</li>
			<br />
			<li>
				Your license to use the Apple-Enabled Software is limited to a
				non-transferable license to use the Apple-Enabled Software on an
				iOS product that you own or control, as permitted by the “Usage
				Rules” set forth in the Apple Media Services Terms and
				Conditions, except that such Apple-Enabled Software may be
				accessed and used by other accounts associated with the
				purchaser via Apple’s Family Sharing or volume purchasing
				programs.
			</li>
			<br />
			<li>
				Apple has no obligation whatsoever to provide any maintenance or
				support services with respect to the Apple-Enabled Software.
			</li>
			<br />
			<li>
				Apple is not responsible for any product warranties, whether
				express or implied by law. In the event of any failure of the
				Apple-Enabled Software to conform to any applicable warranty,
				you may notify Apple, and Apple will refund the purchase price
				for the Apple-Enabled Software, if any, to you; and, to the
				maximum extent permitted by applicable law, Apple will have no
				other warranty obligation whatsoever with respect to the
				Apple-Enabled Software, or any other claims, losses,
				liabilities, damages, costs, or expenses attributable to any
				failure to conform to any warranty, which will be Prodia’s sole
				responsibility, to the extent it cannot be disclaimed under
				applicable law.
			</li>
			<br />
			<li>
				Prodia and you acknowledge that Prodia, not Apple, is
				responsible for addressing any claims of you or any third party
				relating to the Apple-Enabled Software or your possession and/or
				use of that Apple-Enabled Software, including: (a) product
				liability claims; (b) any claim that the Apple-Enabled Software
				fails to conform to any applicable legal or regulatory
				requirement; and (c) claims arising under consumer protection,
				privacy, or similar legislation.
			</li>
			<br />
			<li>
				In the event of any third-party claim that the Apple-Enabled
				Software or your possession and use of that Apple-Enabled
				Software infringes that third party’s intellectual property
				rights, as between Prodia and Apple, Prodia, not Apple, will be
				solely responsible for the investigation, defense, settlement,
				and discharge of any such intellectual property infringement
				claim.
			</li>
			<br />
			<li>
				You represent and warrant that (a) you are not located in a
				country that is subject to a U.S. Government embargo, or that
				has been designated by the U.S. Government as a “terrorist
				supporting” country; and (b) you are not listed on any U.S.
				Government list of prohibited or restricted parties.
			</li>
			<br />
			<li>
				If you have any questions, complaints, or claims with respect to
				the Apple-Enabled Software, they should be directed to Prodia as
				follows: <br />
				hello@prodia.com <br />
				(470) 737-0633 <br />
				1155 Mount Vernon HWY NE, Suite 800 <br />
				Atlanta, GA 30338 <br />
			</li>
			<br />
			<li>
				You must comply with applicable third-party terms of agreement
				when using the Apple-Enabled Software, e.g., your wireless data
				service agreement.
			</li>
			<br />
			<li>
				Prodia and you acknowledge and agree that Apple, and Apple’s
				subsidiaries, are third-party beneficiaries of these Terms with
				respect to the Apple-Enabled Software, and that, upon your
				acceptance of the terms and conditions of these Terms, Apple
				will have the right (and will be deemed to have accepted the
				right) to enforce these Terms against you with respect to the
				Apple-Enabled Software as a third-party beneficiary thereof.
			</li>
			<br />
			<p>
				Google-Sourced Software: The following applies to any Mobile App
				you download from the Google Play Store (“Google-Sourced
				Software”): (a) you acknowledge that these Terms are between you
				and Prodia only, and not with Google, Inc. (“Google”); (b) your
				use of Google-Sourced Software must comply with Google’s
				then-current Google Play Terms of Service; (c) Google is only a
				provider of Google Play where you obtained the Google-Sourced
				Software; (d) Prodia, and not Google, is solely responsible for
				Prodia’s Google-Sourced Software; (e) Google has no obligation
				or liability to you with respect to Google-Sourced Software or
				these Terms; and (f) you acknowledge and agree that Google is a
				third-party beneficiary to these Terms as it relates to Prodia’s
				Google-Sourced Software.
			</p>
			<br />
			<p>
				Open Source Software: The Software may contain or be provided
				together with open source software. Each item of open source
				software is subject to its own license terms. If required by any
				license for particular open source software, Prodia makes such
				open source software, and Prodia’s modifications to that open
				source software (if any), available by written request to
				hello@prodia.com. Copyrights to the open source software are
				held by the respective copyright holders indicated therein.
			</p>
			<br />
			<h2>Third-Party Services and Websites</h2>
			<p>
				The Services may provide links or other access to services,
				sites, technology, and resources that are provided or otherwise
				made available by third parties (the “Third-Party Services”).
				Additionally, you may enable or log in to the Services via
				various online Third-Party Services, such as social media and
				social networking services like Facebook or Twitter. Your access
				and use of the Third-Party Services may also be subject to
				additional terms and conditions, privacy policies, or other
				agreements with such third party, and you may be required to
				authenticate to or create separate accounts to use Third-Party
				Services on the websites or via the technology platforms of
				their respective providers. Some Third-Party Services will
				provide us with access to certain information that you have
				provided to third parties, including through such Third-Party
				Services, and we will use, store and disclose such information
				in accordance with our Privacy Policy. For more information
				about the implications of activating Third-Party Services and
				our use, storage and disclosure of information related to you
				and your use of such Third-Party Services within the Service,
				please see our Privacy Policy. Prodia has no control over and is
				not responsible for such Third-Party Services, including for the
				accuracy, availability, reliability, or completeness of
				information shared by or available through Third-Party Services,
				or on the privacy practices of Third-Party Services. We
				encourage you to review the privacy policies of the third
				parties providing Third-Party Services prior to using such
				services. You, and not Prodia, will be responsible for any and
				all costs and charges associated with your use of any
				Third-Party Services. Prodia enables these Third-Party Services
				merely as a convenience and the integration or inclusion of such
				Third-Party Services does not imply an endorsement or
				recommendation. Any dealings you have with third parties while
				using the Services are between you and the third party. Prodia
				will not be responsible or liable, directly or indirectly, for
				any damage or loss caused or alleged to be caused by or in
				connection with use of or reliance on any Third-Party Services.
			</p>
			<br />
			<h2>Indemnification</h2>
			<p>
				To the extent permitted under applicable law, you agree to
				defend, indemnify, and hold harmless Prodia, its affiliates, and
				its and their respective officers, employees, directors, service
				providers, licensors, and agents (collectively, the “Prodia
				Parties”) from any and all losses, damages, expenses, including
				reasonable attorneys’ fees, rights, claims, actions of any kind,
				and injury (including death) arising out of or relating to your
				use of the Service, any of your Content, your connection to the
				Services, your violation of these Terms, or your violation of
				any rights of another. Prodia will provide notice to you of any
				such claim, suit, or proceeding. Prodia reserves the right to
				assume the exclusive defense and control of any matter which is
				subject to indemnification under this section, and you agree to
				cooperate with any reasonable requests assisting Prodia’s
				defense of such matter. You may not settle or compromise any
				claim against the Prodia Parties without Prodia’s written
				consent.
			</p>
			<br />
			<h2>Disclaimer</h2>
			<p>
				YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES ARE
				PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. EXCEPT TO THE
				EXTENT PROHIBITED BY LAW, WE MAKE NO WARRANTIES (EXPRESS,
				IMPLIED, STATUTORY, OR OTHERWISE) WITH RESPECT TO THE SERVICES
				OR CONTENT, AND DISCLAIM ALL WARRANTIES, INCLUDING, BUT NOT
				LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
				PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. PRODIA MAKES NO
				WARRANTY THAT (A) THE SERVICES WILL MEET YOUR REQUIREMENTS, (B)
				THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
				ERROR-FREE, (C) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF
				THE SERVICES WILL BE ACCURATE OR RELIABLE OR (D) THE QUALITY OF
				ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED
				OR OBTAINED BY YOU THROUGH THE SERVICES WILL MEET YOUR
				EXPECTATIONS.
			</p>
			<br />
			<p>
				We reserve the right, at our sole discretion, to change or
				modify portions of these Terms at any time. If we do this, we
				will post the changes on this page and will indicate at the top
				of this page the date these Terms were last revised. You may
				read a current, effective copy of these Terms by visiting the
				“Terms” link on our website. We will also notify you of any
				material changes, either through the Services user interface, a
				pop-up notice, email, or through other reasonable means. Your
				continued use of the Services after the date any such changes
				become effective constitutes your acceptance of the new Terms.
				You should periodically visit this page to review the current
				Terms so you are aware of any revisions. If you do not agree to
				abide by these or any future Terms, you will not access, browse,
				or use (or continue to access, browse, or use) the Services.
			</p>
			<br />
			<h2>Limitation of Liability</h2>
			<p>
				YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE PRODIA PARTIES WILL
				NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
				CONSEQUENTIAL, EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS
				INCLUDING DAMAGES FOR LOSS OF GOODWILL, USE, OR DATA OR OTHER
				INTANGIBLE LOSSES (EVEN IF THE PRODIA PARTIES HAVE BEEN ADVISED
				OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER BASED ON CONTRACT,
				TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, RESULTING
				FROM: (A) THE USE OR THE INABILITY TO USE THE SERVICES OR ANY
				RELATED INFORMATION; (B) THE COST OF PROCUREMENT OF SUBSTITUTE
				GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION,
				OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR
				TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICES; (C)
				UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR CONTENT,
				TRANSMISSIONS OR DATA; (D) STATEMENTS OR CONDUCT OF ANY THIRD
				PARTY ON THE SERVICES; OR (E) ANY OTHER MATTER RELATING TO THE
				SERVICES. IN NO EVENT WILL THE PRODIA PARTIES’ TOTAL LIABILITY
				TO YOU FOR ALL DAMAGES, LOSSES, OR CAUSES OF ACTION EXCEED THE
				AMOUNT YOU HAVE PAID PRODIA IN THE LAST SIX (6) MONTHS, OR, IF
				GREATER, ONE HUNDRED DOLLARS ($100).
			</p>
			<br />
			<p>
				SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF
				CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY
				FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF
				THE ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU OR BE
				ENFORCEABLE WITH RESPECT TO YOU. IF YOU ARE DISSATISFIED WITH
				ANY PORTION OF THE SERVICES OR WITH THESE TERMS, YOUR SOLE AND
				EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE SERVICES.
			</p>
			<br />
			<p>
				IF YOU ARE A USER FROM NEW JERSEY, THE FOREGOING SECTIONS TITLED
				“INDEMNIFICATION”, “DISCLAIMER OF WARRANTIES” AND “LIMITATION OF
				LIABILITY” ARE INTENDED TO BE ONLY AS BROAD AS IS PERMITTED
				UNDER THE LAWS OF THE STATE OF NEW JERSEY. IF ANY PORTION OF
				THESE SECTIONS IS HELD TO BE INVALID UNDER THE LAWS OF THE STATE
				OF NEW JERSEY, THE INVALIDITY OF SUCH PORTION WILL NOT AFFECT
				THE VALIDITY OF THE REMAINING PORTIONS OF THE APPLICABLE
				SECTIONS.
			</p>
			<br />
			<h2>Dispute Resolution By Binding Arbitration</h2>
			<h2>
				PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS.
			</h2>
			<br />
			<p><b>a. Agreement to Arbitrate</b></p>
			<br />
			<p>
				This Dispute Resolution by Binding Arbitration section is
				referred to in these Terms as the “Arbitration Agreement.” You
				agree that any and all disputes or claims that have arisen or
				may arise between you and Prodia, whether arising out of or
				relating to these Terms (including any alleged breach thereof),
				the Service, any advertising, or any aspect of the relationship
				or transactions between us, will be resolved exclusively through
				final and binding arbitration, rather than a court, in
				accordance with the terms of this Arbitration Agreement, except
				that you may assert individual claims in small claims court, if
				your claims qualify. Further, this Arbitration Agreement does
				not preclude you from bringing issues to the attention of
				federal, state, or local agencies, and such agencies can, if the
				law allows, seek relief against us on your behalf. You agree
				that, by entering into these Terms, you and Prodia are each
				waiving the right to a trial by jury or to participate in a
				class action. Your rights will be determined by a neutral
				arbitrator, not a judge or jury. The Federal Arbitration Act
				governs the interpretation and enforcement of this Arbitration
				Agreement.
			</p>
			<br />
			<p>
				<b
					>b. Prohibition of Class and Representative Actions and
					Non-Individualized Relief</b
				>
			</p>
			<br />
			<p>
				<b>
					YOU AND PRODIA AGREE THAT EACH OF US MAY BRING CLAIMS
					AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A
					PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
					REPRESENTATIVE ACTION OR PROCEEDING. UNLESS BOTH YOU AND
					PRODIA AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE
					OR JOIN MORE THAN ONE PERSON’S OR PARTY’S CLAIMS AND MAY NOT
					OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED,
					REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE ARBITRATOR
					MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND
					DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY
					SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE
					RELIEF NECESSITATED BY THAT PARTY’S INDIVIDUAL CLAIM(S),
					EXCEPT THAT YOU MAY PURSUE A CLAIM FOR AND THE ARBITRATOR
					MAY AWARD PUBLIC INJUNCTIVE RELIEF UNDER APPLICABLE LAW TO
					THE EXTENT REQUIRED FOR THE ENFORCEABILITY OF THIS
					PROVISION.</b
				>
			</p>
			<br />
			<p><b>c. Pre-Arbitration Dispute Resolution</b></p>
			<br />
			<p>
				Prodia is always interested in resolving disputes amicably and
				efficiently, and most customer concerns can be resolved quickly
				and to the customer’s satisfaction by emailing customer support
				at hello@prodia.com. If such efforts prove unsuccessful, a party
				who intends to seek arbitration must first send to the other, by
				certified mail, a written Notice of Dispute (“Notice”). The
				Notice to Prodia should be sent to 1155 Mount Vernon HWY NE,
				Suite 800, Atlanta, GA 30338 (“Notice Address”). The Notice must
				(i) describe the nature and basis of the claim or dispute and
				(ii) set forth the specific relief sought. If Prodia and you do
				not resolve the claim within sixty (60) calendar days after the
				Notice is received, you or Prodia may commence an arbitration
				proceeding. During the arbitration, the amount of any settlement
				offer made by Prodia or you will not be disclosed to the
				arbitrator until after the arbitrator determines the amount, if
				any, to which you or Prodia is entitled.
			</p>
			<br />
			<p><b>d. Arbitration Procedures</b></p>
			<br />
			<p>
				Arbitration will be conducted by a neutral arbitrator in
				accordance with the American Arbitration Association’s (“AAA”)
				rules and procedures, including the AAA’s Consumer Arbitration
				Rules (collectively, the “AAA Rules”), as modified by this
				Arbitration Agreement. For information on the AAA, please visit
				its website,
				<a href="http://www.adr.org">http://www.adr.org</a>. Information
				about the AAA Rules and fees for consumer disputes can be found
				at the AAA’s consumer arbitration page,
				<a href="https://www.adr.org/consumer"
					>https://www.adr.org/consumer</a
				>. If there is any inconsistency between any term of the AAA
				Rules and any term of this Arbitration Agreement, the applicable
				terms of this Arbitration Agreement will control unless the
				arbitrator determines that the application of the inconsistent
				Arbitration Agreement terms would not result in a fundamentally
				fair arbitration. The arbitrator must also follow the provisions
				of these Terms as a court would. All issues are for the
				arbitrator to decide, including issues relating to the scope,
				enforceability, and arbitrability of this Arbitration Agreement.
				Although arbitration proceedings are usually simpler and more
				streamlined than trials and other judicial proceedings, the
				arbitrator can award the same damages and relief on an
				individual basis that a court can award to an individual under
				these Terms and applicable law. Decisions by the arbitrator are
				enforceable in court and may be overturned by a court only for
				very limited reasons.
			</p>
			<br />
			<p>
				Unless Prodia and you agree otherwise, any arbitration hearings
				will take place in a reasonably convenient location for both
				parties with due consideration of their ability to travel and
				other pertinent circumstances. If the parties are unable to
				agree on a location, the determination will be made by AAA. If
				your claim is for $10,000 or less, Prodia agrees that you may
				choose whether the arbitration will be conducted solely on the
				basis of documents submitted to the arbitrator, through a
				telephonic hearing, or by an in-person hearing as established by
				the AAA Rules. If your claim exceeds $10,000, the right to a
				hearing will be determined by the AAA Rules. Regardless of the
				manner in which the arbitration is conducted, the arbitrator
				will issue a reasoned written decision sufficient to explain the
				essential findings and conclusions on which the award is based.
			</p>
			<br />
			<p><b>e. Costs of Arbitration</b></p>
			<br />
			<p>
				Payment of all filing, administration, and arbitrator fees
				(collectively, the “Arbitration Fees”) will be governed by the
				AAA Rules, unless otherwise provided in this Arbitration
				Agreement. To the extent any Arbitration Fees are not
				specifically allocated to either Prodia or you under the AAA
				Rules, Prodia and you shall split them equally; provided that if
				you are able to demonstrate to the arbitrator that you are
				economically unable to pay your portion of such Arbitration Fees
				or if the arbitrator otherwise determines for any reason that
				you should not be required to pay your portion of any
				Arbitration Fees, Prodia will pay your portion of such fees. In
				addition, if you demonstrate to the arbitrator that the costs of
				arbitration will be prohibitive as compared to the costs of
				litigation, Prodia will pay as much of the Arbitration Fees as
				the arbitrator deems necessary to prevent the arbitration from
				being cost-prohibitive. Any payment of attorneys’ fees will be
				governed by the AAA Rules.
			</p>
			<br />
			<p><b>f. Confidentiality</b></p>
			<br />
			<p>
				All aspects of the arbitration proceeding, and any ruling,
				decision, or award by the arbitrator, will be strictly
				confidential for the benefit of all parties.
			</p>
			<br />
			<p><b>g. Severability</b></p>
			<br />
			<p>
				If a court or the arbitrator decides that any term or provision
				of this Arbitration Agreement (other than the subsection (b)
				above titled “Prohibition of Class and Representative Actions
				and Non-Individualized Relief” above) is invalid or
				unenforceable, the parties agree to replace such term or
				provision with a term or provision that is valid and enforceable
				and that comes closest to expressing the intention of the
				invalid or unenforceable term or provision, and this Arbitration
				Agreement will be enforceable as so modified. If a court or the
				arbitrator decides that any of the provisions of subsection (b)
				above titled “Prohibition of Class and Representative Actions
				and Non-Individualized Relief” are invalid or unenforceable,
				then the entirety of this Arbitration Agreement will be null and
				void, unless such provisions are deemed to be invalid or
				unenforceable solely with respect to claims for public
				injunctive relief. The remainder of these Terms will continue to
				apply.
			</p>
			<br />
			<p><b>h. Future Changes to Arbitration Agreement</b></p>
			<br />
			<p>
				Notwithstanding any provision in these Terms to the contrary,
				Prodia agrees that if it makes any future change to this
				Arbitration Agreement (other than a change to the Notice
				Address) while you are a user of the Service, you may reject any
				such change by sending Prodia written notice within thirty (30)
				calendar days of the change to the Notice Address provided
				above. By rejecting any future change, you are agreeing that you
				will arbitrate any dispute between us in accordance with the
				language of this Arbitration Agreement as of the date you first
				accepted these Terms (or accepted any subsequent changes to
				these Terms).
			</p>
			<br />
			<h2>User Disputes</h2>

			<p>
				You agree that you are solely responsible for your interactions
				with any other user in connection with the Service, and Prodia
				will have no liability or responsibility with respect thereto.
				Prodia reserves the right, but has no obligation, to become
				involved in any way with disputes between you and any other user
				of the Service.
			</p>
			<br />
			<h2>General</h2>
			<p>
				These Terms (together with the terms incorporated by reference
				herein) constitute the entire agreement between you and Prodia
				governing your access and use of the Services, and supersede any
				prior agreements between you and Prodia with respect to the
				Services. You also may be subject to additional terms and
				conditions that may apply when you use Third-Party Services,
				third-party content or third-party software. These Terms will be
				governed by the laws of the State of Georgia without regard to
				its conflict of law provisions. With respect to any disputes or
				claims not subject to arbitration, as set forth above, you and
				Prodia submit to the personal and exclusive jurisdiction of the
				state and federal courts located within Georgia. The failure of
				Prodia to exercise or enforce any right or provision of these
				Terms will not constitute a waiver of such right or provision.
				If any provision of these Terms is found by a court of competent
				jurisdiction to be invalid, the parties nevertheless agree that
				the court should endeavor to give effect to the parties’
				intentions as reflected in the provision, and the other
				provisions of these Terms remain in full force and effect. You
				agree that regardless of any statute or law to the contrary, any
				claim or cause of action arising out of or related to use of the
				Services or these Terms must be filed within one (1) year after
				such claim or cause of action arose or be forever barred. A
				printed version of these Terms and of any notice given in
				electronic form will be admissible in judicial or administrative
				proceedings based upon or relating to these Terms to the same
				extent and subject to the same conditions as other business
				documents and records originally generated and maintained in
				printed form. You may not assign these Terms without the prior
				written consent of Prodia, but Prodia may assign or transfer
				these Terms, in whole or in part, without restriction. The
				section titles in these Terms are for convenience only and have
				no legal or contractual effect. As used in these Terms, the
				words “include” and “including,” and variations thereof, will
				not be deemed to be terms of limitation, but rather will be
				deemed to be followed by the words “without limitation.” Notices
				to you may be made via either email or regular mail. The
				Services may also provide notices to you of changes to these
				Terms or other matters by displaying notices or links to notices
				generally on the Service. Prodia will not be in default
				hereunder by reason of any failure or delay in the performance
				of its obligations where such failure or delay is due to civil
				disturbances, riot, epidemic, hostilities, war, terrorist
				attack, embargo, natural disaster, acts of God, flood, fire,
				sabotage, fluctuations or unavailability of electrical power,
				network access or equipment, or any other circumstances or
				causes beyond Prodia’s reasonable control.
			</p>
			<br />
			<h2>Notice for California Users</h2>

			<p>
				Under California Civil Code Section 1789.3, users of the
				Services from California are entitled to the following specific
				consumer rights notice: The Complaint Assistance Unit of the
				Division of Consumer Services of the California Department of
				Consumer Affairs may be contacted (a) via email at
				dca@dca.ca.gov; (b) in writing at: Department of Consumer
				Affairs, Consumer Information Division, 1625 North Market Blvd.,
				Suite N 112, Sacramento, CA 95834; or (c) by telephone at (800)
				952-5210 or (800) 326-2297 (TDD). Sacramento-area consumers may
				call (916) 445-1254 or (916) 928-1227 (TDD). You may contact us
				at Prodia Labs Inc., 1155 Mount Vernon HWY NE, Suite 800
				Atlanta, GA 30338 or (470) 322-0633.
			</p>
			<br />
			<h2>U.S. Government Restricted Rights</h2>

			<p>
				The Service is made available to the U.S. government with
				“RESTRICTED RIGHTS.” Use, duplication, or disclosure by the U.S.
				government is subject to the restrictions contained in 48 CFR
				52.227-19 and 48 CFR 252.227-7013 et seq. or its successor.
				Access or use of the Services (including the Software) by the
				U.S. government constitutes acknowledgement of our proprietary
				rights in the Services (including any third party software).
			</p>
			<br />
			<h2>Questions? Concerns? Suggestions?</h2>
			<p>
				Please contact us at
				<a href="mailto:hello@prodia.com">hello@prodia.com</a> or mail
				to 1155 Mount Vernon HWY NE, Suite 800 Atlanta, GA 30338 to
				report any violations of these Terms or to pose any questions
				regarding these Terms or the Services.
			</p>
		</div>
	</div>
</template>

<style scoped>
.terms-container {
	margin-left: 70px;
}
.terms {
	padding-top: 124px;
	display: flex;
	flex-direction: column;
	padding-bottom: 130px;
	text-align: justify;
	width: 90%;
	margin: 0 auto;
}

h2 {
	font-weight: 500;
}

a {
	cursor: pointer;
	font-weight: 500;
	text-decoration: underline;
}

a:hover {
	text-decoration: none;
}

@media screen and (max-width: 738px) {
	.terms-container {
		margin: 0;
	}
	.terms {
		width: 80%;
		margin: 0 auto;
		padding-top: 180px;
		text-align: left;
	}
}
</style>
