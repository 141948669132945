<template>
	<svg
		width="82"
		height="146"
		viewBox="0 0 82 146"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M0 0C10.7793 4.47069 20.1471 12.5179 24.7668 23.7585C30.5415 37.937 27.975 54.4147 24.2535 69.3596C23.0986 74.0857 21.687 78.8119 21.5587 83.6658C20.9171 100.527 34.9045 114.833 50.3036 118.409C55.8216 119.687 61.5962 119.942 66.7293 122.241C74.0438 125.563 79.4335 132.971 81.3584 141.274C81.7434 142.807 81.8717 144.339 82 146H0V0Z"
			fill="#140D24"
		/>
	</svg>
</template>
