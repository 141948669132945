import { ref, computed } from "vue";
import { config } from "@/config";

export const artGenerations = ref<
	[
		{
			month: string;
			count: number;
		},
		{
			month: string;
			count: number;
		},
		{
			month: string;
			count: number;
		}
	]
>([
	{
		month: "ERR",
		count: 33
	},
	{
		month: "ERR",
		count: 50
	},
	{
		month: "ERR",
		count: 100
	}
]);

(async () => {
	artGenerations.value = await (
		await fetch(`${config.arranUrl}/stats`)
	).json();
})();

export const totalGenerations = computed(
	() => artGenerations.value[artGenerations.value.length - 1]?.count
);
