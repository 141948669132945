<template>
	<div class="cookie-container">
		<div class="cookie">
			<h1>Prodia Cookie Policy</h1>
			<p>Last Updated: January 26th, 2023</p>
			<br />
			<p><b>Does Prodia Use Cookies? </b></p>
			<br />
			<p>
				Yes. As described in our Privacy Policy, Prodia and our
				marketing partners, affiliates, and analytics service providers
				use cookies and other technologies to ensure everyone who uses
				Prodia has the best possible experience. Cookies also help us
				keep your account safe.
			</p>
			<br />
			<p>
				By choosing to use the Site after having been notified of our
				use of cookies in the ways described in this Cookie Policy, and,
				in applicable jurisdictions, through notice and unambiguous
				acknowledgement of your consent, you agree to such use.
			</p>
			<br />
			<p><b>What is a Cookie?</b></p>
			<br />
			<p>
				Cookies are text files that websites store on a visitor’s device
				to uniquely identify the visitor’s browser or to store
				information or settings in the browser for the purpose of
				helping you navigate between pages efficiently, remembering your
				preferences, enabling functionality, helping us understand user
				activity and patterns, and facilitating online advertising. Some
				of the cookies will only be used if you use certain features or
				select certain preferences, and some cookies will always be
				used.
			</p>
			<br />
			<p>
				We use both persistent cookies and session cookies. Persistent
				cookies stay on your device for a set period of time or until
				you delete them, while session cookies are deleted once you
				close your web browser. We use persistent cookies, for example,
				to remember any preferences you have or choices you make when
				you use our website. The cookies placed through your use of our
				website are either set by us (first-party cookies) or by a third
				party at our request (third-party cookies). We also allow our
				advertising partners to collect this information through our
				website.
			</p>
			<br />
			<p>
				This Cookie Policy also governs other types of tracking
				technologies used through our Services, namely:
			</p>
			<br />
			<li>
				<b>Pixels: </b>A pixel is a small amount of code on a web page
				or in an email notification that typically works in conjunction
				with cookies to identify users and record user behavior. We use
				pixels to learn about whether you have interacted with certain
				web or email content. This helps us analyze and improve the Site
				and personalize your experience.
			</li>
			<br />
			<li>
				<b>Local Storage: </b>Local storage is an industry standard
				technology that allows a site or application to store
				information locally on your computer or mobile device. We use
				local storage, including HTML5, to customize what we show you
				based on your content consumption and other past interactions
				with our Services.
			</li>
			<br />
			<li>
				<b>Device Identifiers: </b>Device identifiers are distinctive
				numbers associated with a smartphone or similar handheld device.
				We use mobile device identifiers for purposes set out in this
				Cookie Policy, for example to recognize your device when you
				return to the Site or otherwise use our Services.
			</li>
			<br />
			<p><b>What Types of Cookies Does Prodia Use?</b></p>
			<br />
			<p>Prodia may use the following four categories of cookies:</p>
			<br />
			<table>
				<tr>
					<th>Category</th>
					<th>Purpose</th>
				</tr>
				<tr>
					<td>Strictly Necessary</td>
					<td>
						Prodia.com uses cookies that are essential in order to
						enable you to access our Site and use its features.
						These cookies allow us to provide the critical services
						necessary for the Site to function. The information
						collected through these cookies is used for this purpose
						only and is never shared with third parties.
					</td>
				</tr>
				<tr>
					<td>Functionality</td>
					<td>
						These cookies allow us to remember the choices you make
						and provide enhanced, more personal features. Examples
						may include your username, language, region, and other
						customizations. The information collected through these
						cookies is used for this purpose only and is never
						shared with third parties.
					</td>
				</tr>
				<tr>
					<td>Performance & Analytics</td>
					<td>
						We work with third parties who use cookies to collect
						data that helps us understand how people are using our
						Site. We use this information to identify improvements
						we can make to the user experience. <br />Examples of
						the third-party analytics cookies we use include Google
						Analytics. Specifically, we use Google Analytics to
						collect information about how users use our services,
						which we then use to compile reports that disclose
						trends without identifying individual visitors and help
						us improve our Services. For more information on Google
						Analytics, click here. For more information about
						Google’s privacy practices, click here. You can opt out
						of Google Analytics by downloading and installing the
						browser plug-in available at:
						<a href="https://tools.google.com/dlpage/gaoptout"
							>https://tools.google.com/dlpage/gaoptout.</a
						>
					</td>
				</tr>
				<tr>
					<td>Targeting</td>
					<td>
						We work with third parties who use cookies to collect
						information about the pages, links, and other sites that
						you visit from the Site. These cookies are used to
						deliver Prodia advertisements that are more relevant to
						you and your interests, when you are on other sites
						across the internet. The information collected by these
						cookies will be used to track visits and actions on our
						Site, as well as to deliver reporting and analytics to
						us and our partners.
					</td>
				</tr>
			</table>
			<br />
			<p><b>How do I Limit Online Tracking?</b></p>
			<br />
			<p>
				Depending on the region from which you access our Services, our
				Site may present you with a cookie banner or other interface to
				provide permissions before we or our service providers set
				non-necessary cookies. In this case, we only set these
				non-necessary cookies with your consent. Where this option is
				available, you may revoke your consent at any time with future
				effect by clicking on the cookie settings link in the bottom
				corner of the Site at any time.
			</p>
			<br />
			<p>
				There are a number of ways to limit online tracking, which we
				have summarized below:
			</p>
			<br />
			<li>
				<b>Blocking Cookies in Your Browser. </b>Most browsers let you
				remove or reject cookies, including cookies used for
				interest-based advertising. To do this, follow the instructions
				in your browser settings. Many browsers accept cookies by
				default until you change your settings. For more information
				about cookies, including how to see what cookies have been set
				on your device and how to manage and delete them, visit
				allaboutcookies.org. Use the following links to learn more about
				how to control cookies and online tracking through your browser:
				<b> Firefox; Chrome; Microsoft Edge; Safari.</b>
			</li>
			<br />
			<li>
				<b>Blocking Advertising ID Use in Your Mobile Settings. </b>Your
				mobile device settings may provide functionality to limit use of
				the advertising ID associated with your mobile device for
				interest-based advertising purposes.
			</li>
			<br />
			<li>
				<b>Using Privacy Plug-Ins or Browsers. </b>You can block our
				Services from setting cookies used for interest-based ads by
				using a browser with privacy features, like Brave, or installing
				browser plugins like Privacy Badger, DuckDuckGo, Ghostery, or
				uBlock Origin, and configuring them to block third party
				cookies/trackers.
			</li>
			<br />
			<li>
				<b>Advertising Industry Opt Out Tools. </b>You can also use
				these opt out options to limit use of your information for
				interest-based advertising by participating companies:
				<b
					>Digital Advertising Alliance, Network Advertising
					Initiative.</b
				>
			</li>
			<br />
			<li>
				<b></b>Platform Opt Outs. The following advertising partners
				offer opt out features that let you opt out of use of your
				information for interest-based advertising:
				<b
					>Google opt out, Microsoft opt out, Twitter opt out, Discord
					opt out.
				</b>
			</li>
			<br />
			<p>
				Note that the above opt out mechanisms are specific to the
				device or browser on which they are exercised. You will need to
				opt out on every browser and device that you use.
			</p>
			<br />
			<p><b>How Can I Contact Prodia?</b></p>
			<br />
			<p>
				If you have any questions about our Cookie Policy or our data
				practices, then please contact us at
				<a href="mailto: hello@prodia.com">hello@prodia.com</a>, or by
				post at: <br />
				<i
					>1155 Mount Vernon HWY NE <br />
					Suite 800 <br />
					Atlanta, GA 30338 <br />
					470-737-0633 <br />
				</i>
			</p>
		</div>
	</div>
</template>

<style scoped>
table {
	border-collapse: collapse;
	width: 100%;
}

th {
	background-color: #fb3657;
}

td,
tr,
th {
	padding: 8px;
	border: 1px solid #ddd;
}
.cookie-container {
	margin-left: 70px;
}
.cookie {
	padding-top: 124px;
	display: flex;
	flex-direction: column;
	padding-bottom: 130px;
	text-align: justify;
	width: 90%;
	margin: 0 auto;
}

h2 {
	font-weight: 500;
}

a {
	cursor: pointer;
	font-weight: 500;
	text-decoration: underline;
}

a:hover {
	text-decoration: none;
}

@media screen and (max-width: 738px) {
	.cookie-container {
		margin: 0;
	}
	.cookie {
		width: 80%;
		margin: 0 auto;
		padding-top: 180px;
		text-align: left;
	}

	table {
		margin: 0 auto;

		width: 100%;
	}
}

@media screen and (max-width: 558px) {
	table {
		font-size: 2.5vw;
	}
}
</style>
