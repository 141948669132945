<template>
	<svg
		width="22"
		height="23"
		viewBox="0 0 22 23"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5.50042 9.3313H0.78709C0.354421 9.3313 0.00460287 9.68112 0 10.1184V21.9019C0 22.3392 0.354421 22.689 0.78709 22.689H5.50042C5.9377 22.689 6.28751 22.3345 6.28751 21.9019V10.1184C6.28751 9.68112 5.93309 9.3313 5.50042 9.3313Z"
		/>
		<path
			d="M5.50042 9.3313H0.78709C0.354421 9.3313 0.00460287 9.68112 0 10.1184V21.9019C0 22.3392 0.354421 22.689 0.78709 22.689H5.50042C5.9377 22.689 6.28751 22.3345 6.28751 21.9019V10.1184C6.28751 9.68112 5.93309 9.3313 5.50042 9.3313Z"
		/>
		<path
			d="M13.3577 0.692261H8.64449C8.21183 0.692261 7.85742 1.04207 7.85742 1.47474V21.902C7.85742 22.3392 8.21183 22.689 8.64449 22.689H13.3577C13.795 22.689 14.1448 22.3346 14.1448 21.902V1.47934C14.1448 1.04207 13.7904 0.692261 13.3577 0.692261Z"
		/>
		<path
			d="M13.3577 0.692261H8.64449C8.21183 0.692261 7.85742 1.04207 7.85742 1.47474V21.902C7.85742 22.3392 8.21183 22.689 8.64449 22.689H13.3577C13.795 22.689 14.1448 22.3346 14.1448 21.902V1.47934C14.1448 1.04207 13.7904 0.692261 13.3577 0.692261Z"
		/>
		<path
			d="M21.2132 6.97815H16.5C16.0627 6.97815 15.7129 7.32796 15.7129 7.76523V21.9051C15.7129 22.3424 16.0673 22.6922 16.5 22.6922H21.2132C21.6505 22.6922 22.0003 22.3378 22.0003 21.9051V7.76523C22.0003 7.32796 21.6459 6.97815 21.2132 6.97815Z"
		/>
		<path
			d="M21.2132 6.97815H16.5C16.0627 6.97815 15.7129 7.32796 15.7129 7.76523V21.9051C15.7129 22.3424 16.0673 22.6922 16.5 22.6922H21.2132C21.6505 22.6922 22.0003 22.3378 22.0003 21.9051V7.76523C22.0003 7.32796 21.6459 6.97815 21.2132 6.97815Z"
		/>
	</svg>
</template>
