
import { Chart as ChartJS, registerables } from "chart.js";
ChartJS.register(...registerables);

import { config } from "@/config";
// @ts-ignore
import format from "human-format";
import OwnersIcon from "@/components/svgs/OwnersIcon.vue";

import { ref, computed } from "vue";
import { Line, Bar } from "vue-chartjs";

type TimeCounter = {
	time: number;
	count: number;
}[];

type WaitTimes = {
	priority: number;
	averageWaitTime: number;
}[];

type ActiveWorkers = { [key: string]: number };

type GenerationTimes = {
	worker: string;
	generationTime: number;
}[];

type DailyGenerations = {
	day: string;
	generations: number;
}[];

type Stats = {
	createdSeries: TimeCounter;
	generatedSeries: TimeCounter;
	waitTimes: WaitTimes;
	dailyGenerations: DailyGenerations;
	dailyCumulativeGenerations: DailyGenerations;
	queryTimes: {
		method: string;
		time: number;
	}[];
};

export default {
	setup: () => {
		const stats = ref<Stats | undefined>();

		(async () => {
			for (;;) {
				try {
					stats.value = await (
						await fetch(`${config.arranUrl}/fast-stats`)
					).json();
				} catch (err) {}

				await new Promise((resolve) => setTimeout(resolve, 30 * 1000));
			}
		})();

		const timeCounterChart = computed(() => {
			if (stats.value === undefined) return;

			const labels = stats.value.createdSeries.map((o) =>
				new Date(o.time).toLocaleTimeString()
			);

			const datasets = [
				{
					label: "Created",
					data: stats.value.createdSeries.map((o) => o.count),
					borderWidth: 1
				},
				{
					label: "Generated",
					data: stats.value.generatedSeries.map((o) => o.count),
					borderWidth: 1
				}
			];

			return { labels, datasets };
		});

		const timeCounterChartOptions = {
			responsive: true
		};

		const waitTimesChart = computed(() => {
			if (stats.value === undefined) return;

			const labels = stats.value.waitTimes.map((o) => o.priority);

			const datasets = [
				{
					label: "Average Wait Time",
					data: stats.value.waitTimes.map((o) =>
						(o.averageWaitTime / 1000).toFixed(2)
					)
				}
			];

			return { labels, datasets };
		});

		const waitTimesChartOptions = {
			responsive: true
		};

		const dailyGenerationsChart = computed(() => {
			if (stats.value === undefined) return;

			const labels = stats.value.dailyGenerations.map((o) => o.day);

			const datasets = [
				{
					label: "Generations",
					data: stats.value.dailyGenerations.map((o) =>
						o.generations.toFixed(2)
					)
				}
			];

			return { labels, datasets };
		});

		const dailyGenerationsChartOptions = {
			responsive: true
		};

		const dailyCumulativeGenerationsChart = computed(() => {
			if (stats.value === undefined) return;

			const labels = stats.value.dailyCumulativeGenerations.map(
				(o) => o.day
			);

			const datasets = [
				{
					label: "Cumulative Generations",
					data: stats.value.dailyCumulativeGenerations.map((o) =>
						o.generations.toFixed(2)
					)
				}
			];

			return { labels, datasets };
		});

		const dailyCumulativeGenerationsChartOptions = {
			responsive: true
		};

		const queryTimesChart = computed(() => {
			if (stats.value === undefined) return;

			const labels = stats.value.queryTimes.map((o) => o.method);

			const datasets = [
				{
					label: "Average Time (s)",
					data: stats.value.queryTimes.map((o) => o.time / 1000)
				}
			];

			return { labels, datasets };
		});

		const queryTimesChartOptions = {
			responsive: true
		};

		return {
			stats,
			format: (n: number) => format(n).split(" ").join(""),
			timeCounterChart,
			timeCounterChartOptions,
			waitTimesChart,
			waitTimesChartOptions,
			dailyGenerationsChart,
			dailyGenerationsChartOptions,
			dailyCumulativeGenerationsChart,
			dailyCumulativeGenerationsChartOptions,
			queryTimesChart,
			queryTimesChartOptions
		};
	},
	components: {
		LineChart: Line,
		BarChart: Bar,
		OwnersIcon
	}
};
