<template>
	<svg
		width="18"
		height="19"
		viewBox="0 0 18 19"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14 18.3721H3.99999C2.93162 18.3721 1.92714 17.956 1.17159 17.2004C0.416078 16.445 0 15.4405 0 14.372V13.372C0 12.8197 0.447692 12.372 1.00001 12.372C1.55232 12.372 2.00001 12.8197 2.00001 13.372V14.372C2.00001 14.9063 2.20807 15.4085 2.58576 15.7862C2.96355 16.164 3.46577 16.3721 3.99999 16.3721H14C14.5342 16.3721 15.0364 16.164 15.4141 15.7862C15.7919 15.4084 16 14.9062 16 14.372V13.372C16 12.8197 16.4477 12.372 17 12.372C17.5522 12.372 18 12.8197 18 13.372V14.372C18 15.4404 17.5839 16.4449 16.8284 17.2004C16.0728 17.956 15.0683 18.3721 14 18.3721ZM8.99998 14.372C8.86165 14.372 8.72996 14.3439 8.61014 14.2932C8.49835 14.2459 8.39331 14.1773 8.30141 14.0876C8.30137 14.0876 8.30137 14.0875 8.30134 14.0875C8.30067 14.0869 8.30001 14.0862 8.29935 14.0856C8.29917 14.0854 8.29896 14.0852 8.29879 14.085C8.29823 14.0845 8.29774 14.084 8.29721 14.0835C8.29687 14.0831 8.29655 14.0828 8.2962 14.0825C8.29585 14.0821 8.2954 14.0817 8.29508 14.0814C8.29439 14.0807 8.29362 14.0799 8.29292 14.0792L4.2929 10.0792C3.90239 9.68864 3.90239 9.05546 4.2929 8.66492C4.6834 8.27441 5.31662 8.27438 5.70713 8.66492L8.00001 10.9578V1.37208C7.99998 0.819762 8.44767 0.37207 8.99998 0.37207C9.5523 0.37207 10 0.819762 10 1.37208V10.9578L12.2929 8.66492C12.6833 8.27441 13.3166 8.27441 13.7071 8.66492C14.0976 9.05543 14.0976 9.68864 13.7071 10.0792L9.70708 14.0791C9.70638 14.0798 9.70561 14.0806 9.70492 14.0813C9.70453 14.0817 9.70411 14.0821 9.7038 14.0824C9.70345 14.0828 9.70313 14.0831 9.70278 14.0834C9.7023 14.084 9.70174 14.0844 9.70125 14.0849C9.70107 14.0851 9.70083 14.0854 9.70065 14.0855C9.70002 14.0862 9.69936 14.0868 9.6987 14.0875C9.69866 14.0875 9.69866 14.0875 9.69863 14.0875C9.68762 14.0982 9.67648 14.1087 9.66509 14.1188C9.58136 14.1935 9.48809 14.2517 9.38948 14.2933C9.38913 14.2934 9.38885 14.2936 9.3885 14.2937C9.38811 14.2938 9.3878 14.294 9.38742 14.2941C9.26823 14.3443 9.13737 14.372 8.99998 14.372Z"
			fill="white"
		/>
	</svg>
</template>
