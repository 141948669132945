<template>
	<svg
		width="10"
		height="10"
		viewBox="0 0 10 10"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5.49609 0.371094C3.00895 0.371094 0.996094 2.38372 0.996094 4.87109C0.996094 7.35821 3.00872 9.37109 5.49609 9.37109C7.98324 9.37109 9.99609 7.35847 9.99609 4.87109C9.99609 2.38398 7.98347 0.371094 5.49609 0.371094ZM5.49609 8.74319C3.36102 8.74319 1.624 7.00619 1.624 4.87109C1.624 2.736 3.36102 0.999002 5.49609 0.999002C7.63117 0.999002 9.36819 2.736 9.36819 4.87109C9.36819 7.00619 7.63117 8.74319 5.49609 8.74319Z"
			fill="#D8D8D8"
		/>
		<path
			d="M5.49712 4.12109C5.23056 4.12109 5.04102 4.23366 5.04102 4.39951V6.6563C5.04102 6.79847 5.23056 6.94061 5.49712 6.94061C5.75182 6.94061 5.95912 6.79847 5.95912 6.6563V4.39948C5.95912 4.23365 5.75182 4.12109 5.49712 4.12109Z"
			fill="#D8D8D8"
		/>
		<path
			d="M5.49547 2.73047C5.22299 2.73047 5.00977 2.92594 5.00977 3.15103C5.00977 3.37613 5.22301 3.57752 5.49547 3.57752C5.76202 3.57752 5.97528 3.37613 5.97528 3.15103C5.97528 2.92594 5.762 2.73047 5.49547 2.73047Z"
			fill="#D8D8D8"
		/>
	</svg>
</template>
