<template>
	<svg
		width="97"
		height="120"
		viewBox="0 0 97 120"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M18 120C13 120 8.75 118.25 5.25 114.75C1.75 111.25 0 107 0 102V84H18V3.05176e-05L27 9.00003L36 3.05176e-05L45 9.00003L54 3.05176e-05L63 9.00003L72 3.05176e-05L81 9.00003L90 3.05176e-05L99 9.00003L108 3.05176e-05V102C108 107 106.25 111.25 102.75 114.75C99.25 118.25 95 120 90 120H18ZM90 108C91.7 108 93.125 107.425 94.275 106.275C95.425 105.125 96 103.7 96 102V18H30V84H84V102C84 103.7 84.575 105.125 85.725 106.275C86.875 107.425 88.3 108 90 108ZM36 42V30H72V42H36ZM36 60V48H72V60H36ZM84 42C82.3 42 80.875 41.425 79.725 40.275C78.575 39.125 78 37.7 78 36C78 34.3 78.575 32.875 79.725 31.725C80.875 30.575 82.3 30 84 30C85.7 30 87.125 30.575 88.275 31.725C89.425 32.875 90 34.3 90 36C90 37.7 89.425 39.125 88.275 40.275C87.125 41.425 85.7 42 84 42ZM84 60C82.3 60 80.875 59.425 79.725 58.275C78.575 57.125 78 55.7 78 54C78 52.3 78.575 50.875 79.725 49.725C80.875 48.575 82.3 48 84 48C85.7 48 87.125 48.575 88.275 49.725C89.425 50.875 90 52.3 90 54C90 55.7 89.425 57.125 88.275 58.275C87.125 59.425 85.7 60 84 60ZM18 108H72V96H12V102C12 103.7 12.575 105.125 13.725 106.275C14.875 107.425 16.3 108 18 108Z"
			fill="#044EAF"
		/>
	</svg>
</template>
