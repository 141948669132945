<template>
	<svg
		width="15"
		height="17"
		viewBox="0 0 15 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.5501 4.61133C12.3545 4.61133 12.1668 4.68905 12.0285 4.8274C11.8901 4.96575 11.8124 5.15339 11.8124 5.34904V13.6048C11.7912 13.9779 11.6235 14.3275 11.3458 14.5774C11.0681 14.8274 10.7028 14.9575 10.3296 14.9394H4.44262C4.06941 14.9575 3.70415 14.8274 3.42642 14.5774C3.14868 14.3275 2.98097 13.9779 2.95981 13.6048V5.34904C2.95981 5.15339 2.88208 4.96575 2.74374 4.8274C2.60539 4.68905 2.41775 4.61133 2.22209 4.61133C2.02644 4.61133 1.8388 4.68905 1.70045 4.8274C1.5621 4.96575 1.48438 5.15339 1.48438 5.34904V13.6048C1.50543 14.3693 1.82858 15.0942 2.38305 15.6209C2.93751 16.1476 3.6781 16.433 4.44262 16.4148H10.3296C11.0941 16.433 11.8347 16.1476 12.3892 15.6209C12.9436 15.0942 13.2668 14.3693 13.2878 13.6048V5.34904C13.2878 5.15339 13.2101 4.96575 13.0718 4.8274C12.9334 4.68905 12.7458 4.61133 12.5501 4.61133Z"
			fill="#C4C4C4"
		/>
		<path
			d="M13.2873 2.3987H10.3364V0.923263C10.3364 0.727609 10.2587 0.539968 10.1203 0.401619C9.98199 0.26327 9.79435 0.185547 9.59869 0.185547H5.17239C4.97674 0.185547 4.7891 0.26327 4.65075 0.401619C4.5124 0.539968 4.43468 0.727609 4.43468 0.923263V2.3987H1.48381C1.28816 2.3987 1.10051 2.47642 0.962166 2.61477C0.823817 2.75312 0.746094 2.94076 0.746094 3.13641C0.746094 3.33207 0.823817 3.51971 0.962166 3.65806C1.10051 3.79641 1.28816 3.87413 1.48381 3.87413H13.2873C13.4829 3.87413 13.6706 3.79641 13.8089 3.65806C13.9473 3.51971 14.025 3.33207 14.025 3.13641C14.025 2.94076 13.9473 2.75312 13.8089 2.61477C13.6706 2.47642 13.4829 2.3987 13.2873 2.3987V2.3987ZM5.91011 2.3987V1.66098H8.86098V2.3987H5.91011Z"
			fill="#C4C4C4"
		/>
		<path
			d="M6.64731 11.9896V6.82561C6.64731 6.62995 6.56958 6.44231 6.43124 6.30396C6.29289 6.16561 6.10525 6.08789 5.90959 6.08789C5.71394 6.08789 5.5263 6.16561 5.38795 6.30396C5.2496 6.44231 5.17188 6.62995 5.17188 6.82561V11.9896C5.17188 12.1853 5.2496 12.3729 5.38795 12.5113C5.5263 12.6496 5.71394 12.7273 5.90959 12.7273C6.10525 12.7273 6.29289 12.6496 6.43124 12.5113C6.56958 12.3729 6.64731 12.1853 6.64731 11.9896Z"
			fill="#C4C4C4"
		/>
		<path
			d="M9.59848 11.9896V6.82561C9.59848 6.62995 9.52076 6.44231 9.38241 6.30396C9.24406 6.16561 9.05642 6.08789 8.86076 6.08789C8.66511 6.08789 8.47747 6.16561 8.33912 6.30396C8.20077 6.44231 8.12305 6.62995 8.12305 6.82561V11.9896C8.12305 12.1853 8.20077 12.3729 8.33912 12.5113C8.47747 12.6496 8.66511 12.7273 8.86076 12.7273C9.05642 12.7273 9.24406 12.6496 9.38241 12.5113C9.52076 12.3729 9.59848 12.1853 9.59848 11.9896Z"
			fill="#C4C4C4"
		/>
	</svg>
</template>
