<template>
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			id="mask0_3699_10013"
			style="mask-type: alpha"
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="20"
			height="20"
		>
			<rect width="20" height="20" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_3699_10013)">
			<path
				d="M14.1654 16.6667C13.9293 16.6667 13.7313 16.5868 13.5716 16.4271C13.4119 16.2674 13.332 16.0695 13.332 15.8334V11.6667C13.332 11.4306 13.4119 11.2327 13.5716 11.073C13.7313 10.9132 13.9293 10.8334 14.1654 10.8334H15.832C16.0681 10.8334 16.2661 10.9132 16.4258 11.073C16.5855 11.2327 16.6654 11.4306 16.6654 11.6667V15.8334C16.6654 16.0695 16.5855 16.2674 16.4258 16.4271C16.2661 16.5868 16.0681 16.6667 15.832 16.6667H14.1654ZM9.16536 16.6667C8.92925 16.6667 8.73134 16.5868 8.57161 16.4271C8.41189 16.2674 8.33203 16.0695 8.33203 15.8334V4.16671C8.33203 3.9306 8.41189 3.73268 8.57161 3.57296C8.73134 3.41324 8.92925 3.33337 9.16536 3.33337H10.832C11.0681 3.33337 11.2661 3.41324 11.4258 3.57296C11.5855 3.73268 11.6654 3.9306 11.6654 4.16671V15.8334C11.6654 16.0695 11.5855 16.2674 11.4258 16.4271C11.2661 16.5868 11.0681 16.6667 10.832 16.6667H9.16536ZM4.16536 16.6667C3.92925 16.6667 3.73134 16.5868 3.57161 16.4271C3.41189 16.2674 3.33203 16.0695 3.33203 15.8334V8.33337C3.33203 8.09726 3.41189 7.89935 3.57161 7.73962C3.73134 7.5799 3.92925 7.50004 4.16536 7.50004H5.83203C6.06814 7.50004 6.26606 7.5799 6.42578 7.73962C6.5855 7.89935 6.66536 8.09726 6.66536 8.33337V15.8334C6.66536 16.0695 6.5855 16.2674 6.42578 16.4271C6.26606 16.5868 6.06814 16.6667 5.83203 16.6667H4.16536Z"
				fill="#5DA9E9"
			/>
		</g>
	</svg>
</template>
